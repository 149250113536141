import _ from "lodash";

export function timeConvert(n) {
  let num = n;
  let hours = num / 60;
  let rhours = Math.floor(hours);
  let minutes = (hours - rhours) * 60;
  let rminutes = Math.round(minutes);
  let res = rhours > 0 ? rhours + "h " + rminutes + "m" : rminutes + "m";
  return res;
}
export function listTherapistName(therapist) {
  
  let objTherapist = {};
  _.forEach(therapist, course => {
    // if (course.therapists.length() > 1) {
    //   
    // }
    _.forEach(course.therapists, t => {
      if (objTherapist[t.uid] === undefined) {
        objTherapist[t.uid] = t;
      }
    });
  });
  
  let num = 0;
  let text = "";
  _.forEach(objTherapist, t => {
    if (num >= 1) {
      text += ",";
    }
    text += t.name;
    num += 1;
  });
  return text;
}
export function getTimeStamp(timestamp) {
  ;
  let arrTimeStamp = _.split(timestamp, "#");
  ;
  return arrTimeStamp[1]
}
