import React from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import CardBranch from "./CardBranch";

const SelectNavbar = props => {
  let { appState, shopList } = useSelector(state => {
    return {
      appState: state.appState,
      shopList: state.shopList
    };
  });
  const { mode, branchSelected } = appState;
  const dispatch = useDispatch();

  function selectBranch(value, branchName) {
    let newMode = value === "all" ? "all" : "branch";
    let patchName = value === 'all' ? '/LivePageAll' : 'LivePageBranch'

    dispatch({
      type: "SET_APPSTATE",
      key: "branchName",
      payload: branchName
    });

    dispatch({
      type: "SET_APPSTATE",
      key: "branchSelected",
      payload: value
    });
    dispatch({
      type: "SET_APPSTATE",
      key: "mode",
      payload: newMode
    });
    props.history.push(patchName)
  }

  return (
    <div>
      <div className="card mb-4">
        <div className="card-header">ภาพรวมสาขา</div>
        <div className="card-body p-0">
          
            <div class="card-body">
              {/* <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
              <button type="button" onClick={() => selectBranch("all", "ALL BRANCHES")} class="btn btn-sm btn-primary btn-lg btn-block">Overview</button>
            </div>
            

          
           
        </div>
      </div>
      
      {shopList && (
        <CardBranch
          title={"SelectBranch"}
          data={shopList}
          clickRow={selectBranch}
        />
      )}
    </div>
  );

  
};
export default SelectNavbar;
