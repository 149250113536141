import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Dynamo from "../data/dynamoDb";
import Card from "../Component/CardReceipt";
import _ from "lodash";
import ModalReceipt from "../Component/ModalReceipt";
import moment from "moment";
import DatePicker from "../Component/DatePicker";
import formatNum from "format-num";
import { fetchDataReceipts } from "../data/actions";
import TableReceipst from '../Component/TableReceipts'
import AlertNodata from '../Component/AlertNodata'

const VoidsPage = () => {
  const dispatch = useDispatch();
  const [receipts, setReceipts] = useState([]);
  const [dataModal, setDataModal] = useState({});
  const [modeData, setModeData] = useState('table');


  let { shopInfo, appState, mounthList, voids_fetch } = useSelector(state => {
    return {
      shopInfo: state.shopInfo,
      appState: state.appState,
      mounthList: state.mounthList,
      voids_fetch: state.voids_fetch
    };
  });

  // const {storeUid} =  shopInfo
  const { queryDate, queryMonth, queryYear, branchSelected } = appState;
  useEffect(() => {
    fetchData();
  }, [queryDate]);
  async function fetchData() {
    let options = {
      timestamp: queryDate
    };
    dispatch(await fetchDataReceipts(branchSelected, options));
  }

  function clickCard(data) {
    window.$("#myModal2").modal("show");
    setDataModal(data);
  }

  function changeDate(dateEpocMillisec) {
    dispatch({
      type: "SET_APPSTATE",
      key: "queryDate",
      payload: moment(dateEpocMillisec).format("YYYY-MM-DD")
    });
  }

  return (
    <div className="ReceiptsPage">
      <div className="App">
        <div class="row ">
          <div class="col-12">
            <DatePicker fetchData={fetchData} changeDate={changeDate} />
          </div>
        </div>
        {voids_fetch.length < 1&&
          <div class="row">
          <AlertNodata/>
        </div>
       }
        {voids_fetch.length >= 1 && (
          <div class="row">
            <div class="col-6">
              
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  value="table"
                  onClick={e => {
                    setModeData(e.target.value);
                  }}
                  checked={modeData === "table" ? true : false}
                />
                <label class="form-check-label" for="exampleRadios2" style={{marginTop:2}}>
                  Table
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  value="card"
                  onClick={e => {
                    setModeData(e.target.value);
                  }}
                  checked={modeData === "card" ? true : false}
                />
                <label class="form-check-label" for="exampleRadios1" style={{marginTop:2}}>
                  Card
                </label>
              </div>
            </div>
            <div class="col-6 text-right">
              <div class="Total">
              <span style={{ float: "right" }}>
                  ยอดรวม <strong>{formatNum(_.sumBy(voids_fetch, "payment.grandTotal"))}</strong>{" "}
                  บาท
                </span>
                
              </div>
              <div class="CountBills">
              <span>จำนวนบิล {voids_fetch.length} บิล</span>
              </div>
            </div>
          </div>
        )}

        {/* <p class="mt-3 mb-3">
          <span>จำนวนบิล {voids_fetch.length} บิล</span>
          <span style={{ float: "right" }}>
            ยอดรวม {formatNum(_.sumBy(voids_fetch, "payment.grandTotal"))} บาท
          </span>
        </p> */}
        {modeData === 'card'  && voids_fetch && voids_fetch.length >= 1 &&
          <div class="row">
          {_.map(voids_fetch, (r, key) => {
            return (
              <div class="col-12 mt-2">
                <Card
                  items={r}
                  clickCard={clickCard}
                  setDataModal={setDataModal}
                  voids={true}
                />
              </div>
            );
          })}
        </div>
        }
        {
       modeData === 'table' && voids_fetch && voids_fetch.length >= 1 &&
       <div class="row">
         <div class="col-12 mt-3">
           <TableReceipst receipts={voids_fetch} noReceiptId={true} click={clickCard} voids={true}/>
         </div>
       </div>
     }

        <ModalReceipt dataModal={dataModal} />
      </div>
    </div>
  );
};
export default VoidsPage;
