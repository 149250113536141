import React, { useState, useEffect } from "react";
import _ from "lodash";
import * as Dynamo from "../data/dynamoDb";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import TableHeader from "../Component/DateRange";
import CollapseItems from "../Component/CollapseItems";
import AlertNodata from "../Component/AlertNodata";
import { fetchDataReceipts, fetchDataCompute } from "../data/actions";
import axios from "axios";
import { setLoading } from "../data/actions";

const ProductReportPage = props => {
  let { appState, receipts_fetched } = useSelector(state => {
    return {
      appState: state.appState,
      receipts_fetched: state.receipts_fetched
    };
  });

  const dispatch = useDispatch();
  const {
    headDetailPage,

    branchSelected,
    startDate,
    endDate
  } = appState;
  const shopId = branchSelected;

  const [dataMain, setDataMain] = useState({});
  const [dataReceipts, setDataReceipts] = useState({});
  const [objItems, setObjItems] = useState({});
  const [dataOpenProducts, setDataOpenProducts] = useState([]);

  useEffect(() => {
    getDataFromApi();
  }, [startDate, endDate]);

  function getDataFromApi() {
    dispatch(setLoading(true));

    let param = {
      storeUid: shopId,
      startDate: startDate,
      endDate: endDate
    };

    const headers = {
      "Content-Type": "application/json"
      // 'Authorization': 'JWT fefege...'
    };
    axios
      .post(
        `https://bxiqydcg04.execute-api.ap-southeast-1.amazonaws.com/dev/report/product`,
        param,
        {
          headers: headers
        }
      )
      .then(res => {
        setDataMain(res.data);
        let newsOpenProducts = [
          {
            name: "OpenProducts",
            items: res.data.openProduct
          }
        ];
        setDataOpenProducts(newsOpenProducts);
        dispatch(setLoading(false));

        console.log(res.data);
      });
  }

  return (
    <div className="App">
      <div class="row">
        <div class="col-12">
          <TableHeader />
        </div>

        {dataMain.products &&
          dataMain.products.length < 1 &&
          dataMain.openProduct &&
          dataMain.openProduct.length < 1 && (
            <div class="row">
              <div class="col-12 mt-2">
                <AlertNodata />
              </div>
            </div>
          )}
        {dataMain.products && (
          <div class="col-12 p-0 mt-5">
            {_.map(dataMain.products, products => {
              return _.map(products.groups, g => {
                let id = `ab${g.uid}`;

                return (
                  <div class="col-12">
                    <CollapseItems
                      name={g.name}
                      id={id}
                      Items={g.items}
                      receipts={dataReceipts}
                      objItems={objItems}
                    />
                  </div>
                );
              });
            })}
          </div>
        )}
        {dataMain.openProduct && dataMain.openProduct.length >= 1 && (
          <div class="col-12 p-0 ">
            {_.map(dataOpenProducts, p => {
              let id = `ab${p.name}`;

              return (
                <div class="col-12">
                  <CollapseItems name={p.name} id={id} Items={p.items} />
                </div>
              );
            })}
          </div>
        )}
      </div>
      <div class="row">
        <div class="col-12">
          <div class="accordion" id="accordionExample">
            {/* <CollapseItems /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductReportPage;
