import React from "react";
import _ from "lodash";
import CollapesItems from "./CollapsItemsInTable";
import moment from 'moment'

const ModalLIveBranch = props => {
  const {
    store,
    meta,
    member,
    agent,
    therapist,
    subTotal,
    options = [],
    payment,
    promotions,
    grandTotal,
    items,
    qtyHolder,
    promotionItems,
    entertain,
    jobUid
  } = props;
  

  return (
    <div
      class="modal fade"
      id="ModalLiveBranch"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
          {member && member.name &&
                    <h5 class="modal-title" id="exampleModalCenterTitle">{member.name||''}</h5>
                  }
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div>
              <table class="table table-borderless table-sm">
                <tbody>
                  {store && store.name && (
                    <tr>
                      <td>
                        <small>branch:</small>
                      </td>
                      <td class="text-right">
                        <small>{store.name}</small>
                      </td>
                    </tr>
                  )}
                  {meta && meta.room  && meta.room.label &&  meta.bed && meta.bed.label && (
                    <tr>
                      <td>
                        <small>room:</small>
                      </td>
                      <td class="text-right">
                        <small>{meta.room.label}-{meta.bed.label}</small>
                      </td>
                    </tr>
                  )}
                   
                  {
                    jobUid &&
                    (<tr>
                      <td><small>date:</small></td>
                      <td class="text-right"><small>{moment(jobUid).format('YYYY-MM-DD')} {  moment(jobUid).format("HH:mm") }</small></td>
                    </tr>)
                  }
                  
                 {therapist && therapist.name && (
                    <tr>
                      <td>
                        <small>therapist:</small>
                      </td>
                      <td class="text-right">
                        <small>{therapist.name}</small>
                      </td>
                    </tr>
                  )}
                  
                  {agent && agent.name && (
                    <tr>
                      <td>
                        <small>agent:</small>
                      </td>
                      <td class="text-right">
                        <small>{agent.name}</small>
                      </td>
                    </tr>
                  )}
                   {entertain && entertain.name && (
                    <tr>
                      <td>
                        <small>entertain:</small>
                      </td>
                      <td class="text-right">
                        <small>{entertain.name}</small>
                      </td>
                    </tr>
                  )}
                  
                 
                </tbody>
              </table>

            

              
              <CollapesItems
                name={'Product'}
                Items={items}
                dataHolder={qtyHolder}
              />
              
              
              {

                  (promotionItems && promotionItems.length >=1) &&
                              <CollapesItems
                              name={'Promotion'}
                              Items={promotionItems}
                              dataHolder={qtyHolder}
                            />
              }
              
            </div>
          </div>
          {/* <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary">Save changes</button>
                </div> */}
        </div>
      </div>
    </div>
  );
  
};
export default ModalLIveBranch;
