import React from "react";
import _ from "lodash";
import formatNum from "format-num";

const TableReceipts = props => {
  const { receipts, noReceiptId, live } = props;
  const { click, voids } = props;
  return (
    <div className="card shadow">
      {/* <div className="card-header">PaymentType</div> */}
      <div className="card-body p-0">
        <table class="table mb-0 table-striped">
          <thead>
            <tr>
              {!noReceiptId && (
                <th
                  scope="col"
                  style={{ borderBottom: "none", borderTop: "none" }}
                >
                  ReceiptId
                </th>
              )}
              <th
                scope="col"
                style={{ borderBottom: "none", borderTop: "none" }}
              >
                Customer
              </th>
              <th
                scope="col"
                class="text-right"
                style={{ borderBottom: "none", borderTop: "none" }}
              >
                GrandTotal
              </th>
            </tr>
          </thead>
          <tbody>
            {_.map(receipts, r => {
              return (
                <tr
                  onClick={e => {
                    click(r);
                  }}
                >
                  {!noReceiptId && (
                    <td
                      style={{
                        borderBottom: "none",
                        borderTop: "none",
                        paddingRight: 0
                      }}
                    >
                      {" "}
                      <small class="text-primary">{r.receiptId}</small>
                    </td>
                  )}
                  <td
                    style={{
                      borderBottom: "none",
                      borderTop: "none",
                      paddingRight: 0
                    }}
                  >
                    <img
                      src="http://cdn3.livescore.com/web2/img/flash.gif"
                      alt="live"
                      style={{ marginRight: 3 }}
                      hidden={live ? false : true}
                    />{" "}
                    <small class={`${noReceiptId ? "text-primary" : ""}`}>
                      {(r.member &&
                        r.member.name &&
                        r.member.name.toUpperCase()) ||
                        "-"}
                    </small>
                  </td>

                  {r.payment && r.payment.grandTotal && (
                    <td
                      class="text-right"
                      style={{ borderBottom: "none", borderTop: "none" }}
                    >
                      <span
                        style={{ color: "red" }}
                        hidden={voids ? false : true}
                      >
                        (void)
                      </span>
                      {formatNum(r.payment.grandTotal)}
                    </td>
                  )}
                  {r.grandTotal && (
                    <td
                      class="text-right "
                      style={{ borderBottom: "none", borderTop: "none" }}
                    >
                      {" "}
                      {formatNum(r.grandTotal)}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default TableReceipts;
