import React, { useState, useEffect } from "react";
import _ from "lodash";
import * as Dynamo from "../data/dynamoDb";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import TableHeader from "../Component/DateRange";
import CardReceipt from "../Component/CardReceipt";
import ModalReceipt from "../Component/ModalReceipt";
import ButtonBack from '../Component/ButtonBack'


const EntertainDetailsPage = props => {
  let { appState, receipts_entertain_fetch } = useSelector(state => {
    return {
      appState: state.appState,
      receipts_entertain_fetch: state.receipts_entertain_fetch
    };
  });

  const dispatch = useDispatch();
  const { querySelected  } = appState;
  const [dataModal, setDataModal] = useState({});
  const [dataCard,setDataCard] = useState([])


  const [Header, setHeader] = useState({
    att1: "Name",
    att2: "Bills",
    att3: "GranTotal"
  });

  useEffect(() => {
    filter(receipts_entertain_fetch);
    ;
  }, [receipts_entertain_fetch]);

  function filter(receipts) {
    
   let receipts_filter  = _.filter(receipts, r => {
      if (r.entertain) {
        return r.entertain.uid === querySelected.uid;
      }
    });
    let receipts_filter_orderby = _.orderBy(receipts_filter,'timestamp','desc')
    setDataCard(receipts_filter_orderby)
    ;
  }
  function clickCard(data) {
    ; 
    window.$("#myModal2").modal("show");
    setDataModal(data);
    ;
  }

  return (
    <div className="App">
      <div class="row">
        <div class="col-12">
          <ButtonBack history={props.history}/>
        </div>
      </div>
      
      <div class="row">
        {_.map(dataCard, r => {
            
          return (
            <div class="col-12 mt-2">
              <CardReceipt items={r}  clickCard={clickCard}/>
            </div>
          );
        })}
      </div>
      <ModalReceipt dataModal={dataModal} />
    </div>
  );
};

export default EntertainDetailsPage;
