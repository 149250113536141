import AWS from "aws-sdk";

const ACCESS_KEY = "AKIA2J6ITW3PDVOVL7NY";
const SECRET_KEY = "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";

export const dynamoDbClient = new AWS.DynamoDB.DocumentClient({
  apiVersion: "2012-08-10",
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_KEY,
  region: "ap-southeast-1" // 'https://dynamodb.ap-southeast-1.console.aws.amazon.com'
});
