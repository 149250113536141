import React, { useState, useEffect} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import formatNum from 'format-num'

const CollapseItems = props => {
  const { Items, objItems } = props;
  
  ;

  const { name, id } = props;
  const [toggle, setToggle] = useState(false);



  // const [thisGroupHasSales, setThisGroupHasSales] = useState(false);
  

  useEffect(() => {
    //  let found =  _.find(Items, (line)=>{
    //      return  objItems[line.uid] && objItems[line.uid].grandTotal
    //   })
    //   setThisGroupHasSales(found)
      
  }, [Items, objItems ])

  // if(!thisGroupHasSales){
  //   return null
  // }

  return [
    <h6 onClick={ ()=>    setToggle(!toggle)  }>  {toggle ? "-" : "+"}  {name}</h6>,
    !toggle && <hr/>,
    toggle && <ListItems items={Items} dataHolder={objItems} />
  ];

  
};
export default CollapseItems;

function ListItems({ items = [], dataHolder = {} }) {
  return (
    <div class="list-group list-group-flush mb-5">
      {_.map(items, line => {
        // if (dataHolder[line.uid] == undefined) {
        //   return null;
        // }
        
        return (
          <li class="list-group-item d-flex justify-content-between align-items-center p-0 pt-1">
            <div>
              <h6>
                <span style={{color : 'blue'}}> <b>
                    {line.qty && line.qty}{" "}
                  </b>{" "}</span>
                <small>
                  {" "}
                 
                  {line.name}
                </small>
              </h6>
            </div>
            <div>
              <span class="number">
                {line.qty && formatNum(line.qty * line.price)}
              </span>
            </div>
          </li>
        );
        return (
          <li class="list-group-item d-flex justify-content-between align-items-center p-0 pt-1">
            <div>
              <h6>
                <span style={{color : 'blue'}}> <b>
                    {dataHolder[line.uid] && dataHolder[line.uid].qtyTotal}{" "}
                  </b>{" "}</span>
                <small>
                  {" "}
                 
                  {line.name}
                </small>
              </h6>
            </div>
            <div>
              <span class="number">
                {dataHolder[line.uid] && formatNum(dataHolder[line.uid].grandTotal)}
              </span>
            </div>
          </li>
        );
      })}
    </div>
  );
}
