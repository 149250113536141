

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from 'lodash'

const SelectNavbar = props => {
  let { appState, shopList } = useSelector(state => {
    return {
      appState: state.appState,
      shopList: state.shopList
    };
  });
  const { mode, branchSelected } = appState;
  const dispatch = useDispatch();
  const {data,clickRow} = props

  

  return (
    <div className="CardTable">
        <div className="card">
          <div className="card-header">{props.title}</div>
          <div className="card-body p-0">
            <table class="table  mb-0  table-striped  ">
              <tbody>
                {_.map(data, (d, key) => {
                  
                  return (
                    <tr >
                      <td style={{paddingLeft:'20px'}}>{d.name}</td>
                      <td >
                      <button style={{float : 'right'}} type="button" class="btn btn-sm btn-primary" onClick={e=>{clickRow(d.uid,d.name)}}>Select</button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
  );

  
};
export default SelectNavbar;
