import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import G2 from "../image/G2.png";
import * as Dynamo from "../data/dynamoDb";
import moment from "moment";
import _ from "lodash";
import formatNum from "format-num";
import CardGraph from "../Component/CardGraph";
import Highcharts from "highcharts";
import { fetchDataReceipts } from "../data/actions";
import { fetchDataJobs } from "../data/service";

const LivePageAll = () => {
  const list = [
    {
      name: "Pending",
      mode: "pending"
    },
    {
      name: "Paid",
      mode: "paid"
    },
    {
      name: "Total",
      mode: "total"
    }
  ];
  const [dataJobs, setDataJobs] = useState([]);
  const [dataReceipts, setDataReceipts] = useState([]);

  const [dataTable, setDataTable] = useState({});
  const [objTotal, setObjTotal] = useState({});
  const [objShopList, setObjShopList] = useState({});
  const [options, setOptions] = useState({});

  const dispatch = useDispatch();

  let { appState, user, shopList, receipts_fetched } = useSelector(state => {
    return {
      appState: state.appState,
      user: state.user,
      shopList: state.shopList,
      receipts_fetched: state.receipts_fetched
    };
  });
  const { queryDate, branchSelected } = appState;
  const [modeGraph, setModeGraph] = useState("pending");
  const currentDate = moment().format("YYYY-MM-DD");
  useEffect(() => {
    createObjShopList();
    fetchData(currentDate);
  }, [shopList]);
  useEffect(() => {
    computedData();
  }, [dataJobs, receipts_fetched]);

  async function fetchData(date) {
    let options = {
      hqUid: user.shopId,
      timestamp: date
    };
    let resJobs = await fetchDataJobs(branchSelected, options);
    options = {
      hqUid: user.shopId,
      timestamp: date
    };
    dispatch(fetchDataReceipts(branchSelected, options));
    setDataJobs(resJobs);
  }
  function compuutedObjJobs(dataJobs) {
    let newObjJobs = _.reduce(
      dataJobs,
      (acc, j, key) => {
        if (acc[j.storeUid] === undefined) {
          acc[j.storeUid] = {
            storeUid: j.storeUid,
            pending: 0,
            paid: 0,
            total: 0
          };
        }
        acc[j.storeUid].pending += j.grandTotal;
        acc[j.storeUid].total +=  j.grandTotal;
        

        return acc;
      },
      {}
    );
    return newObjJobs;
  }
  function compuutedObjStoreUid(objJobs) {
    
    let newObjStoreUid = _.reduce(
      
      receipts_fetched,
      (acc, r, key) => {
        
        if (acc[r.storeUid] === undefined) {
          
          acc[r.storeUid] = {
            storeUid: r.storeUid,
            pending: 0,
            paid: 0,
            total: 0
          };
        }
        acc[r.storeUid].paid += r.payment.grandTotal;
        acc[r.storeUid].total = acc[r.storeUid].pending + acc[r.storeUid].paid;
        

        return acc;
      },
      objJobs
    );
    return newObjStoreUid;
  }
  function computedTotal(arrStoreUid) {
    let total = {
      pending: 0,
      paid: 0,
      total: 0
    };
    _.reduce(
      arrStoreUid,
      (acc, d, key) => {
        acc.pending += d.pending;
        acc.paid += d.paid;
        acc.total += d.total;
        return acc;
      },
      total
    );

    return total;
  }
  async function computedData() {
    //TODO: ฟังก์ชันใหญ่เกินไปควรแยกออกเป็นส่วนการทำงาน
    let objJobs = {};
    //Computed Objjobs
    objJobs = compuutedObjJobs(dataJobs);
    

    //Compute ObjStoreuid by objJobs
    let objStoreUid = {};
    objStoreUid = compuutedObjStoreUid(objJobs);
    

    let arrStoreUid = [];
    _.forEach(shopList, s => {
      if (objStoreUid[s.uid]) {
        arrStoreUid.push({ ...objStoreUid[s.uid], name: s.name });
      } else {
        arrStoreUid.push({
          storeUid: s.uid,
          name: s.name,
          total: 0,
          paid: 0,
          pending: 0
        });
      }
    });

    //Compute total
    let newTotal = {};
    newTotal = computedTotal(arrStoreUid);

    setDataTable(arrStoreUid);
    setObjTotal(newTotal);
    mapDataToOptionsChart(arrStoreUid, modeGraph);
  }
  function createObjShopList() {
    let newObjShops = {};
    _.forEach(shopList, s => {
      if (s.branchId) {
        newObjShops[s.uid] = {
          name: s.name || "",
          uid: s.uid || ""
        };
      }
    });

    setObjShopList(newObjShops);
  }
  function mapDataToOptionsChart(dataTable, modeGraph = "pending") {
    let nodeData =
      modeGraph === "pending"
        ? "pending"
        : modeGraph === "paid"
        ? "paid"
        : "total";

    let newOptions = {
      chart: {
        type: "column"
      },
      title: {
        text: "Live"
      },

      xAxis: {
        categories: _.map(dataTable, x => {
          let categories = [x.name];

          return categories;
        }),
        title: {
          text: null
        }
      },
    
      yAxis: {
        min: 0,
        title: {
          text: null
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.0f} mm</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true
      },
      plotOptions: {
        // column: {
        //   pointPadding: 0.2,
        //   borderWidth: 0,
        //   dataLabels: {
        //     enabled: true
        // }
        // }
        series: {
          dataLabels: {
            enabled: true,
            rotation: -90,
            color: "white",
            borderColor: "none",
            align: "right",
            format: "{point.y:,.0f}", // one decimal
            y: 10, // 10 pixels down from the top
            style: {
              fontSize: "auto",
              fontFamily: "Verdana, sans-serif",
              textOutline: "0px"
            }
          }
        }
      },

      series: [
        {
          name: nodeData,
          data: _.map(dataTable, x => {
            let data = x[nodeData];
            return data;
          })
        }
      ]
    };

    setOptions(newOptions);

  }
  function chageModeGraph(modeGraph) {
    setModeGraph(modeGraph);
    mapDataToOptionsChart(dataTable, modeGraph);
  }

  return (
    <div className="LivePageAll">
      <div class="row mb-5">
        <div class="col-12">
          <div class="card shadow">
            <div class="card-body p-0">
              <table class="table mb-0 table-sm table-striped">
                <thead>
                  <tr>
                    <th scope="col">Branch</th>
                    <th scope="col">Pending</th>
                    <th scope="col">Paid</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {_.map(dataTable, d => {
                    return (
                      <tr>
                        <td>
                          <small>{objShopList[d.storeUid].name}</small>
                        </td>
                        <td>
                        <img src="http://cdn3.livescore.com/web2/img/flash.gif" alt="live" style={{marginRight:3}}/>
                          <small>{formatNum(d.pending)}</small>
                        </td>
                        <td>
                          <small>{formatNum(d.paid)}</small>
                        </td>
                        <td>
                          <small>{formatNum(d.total)}</small>
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <th scope="col">
                      <small>Sum</small>
                    </th>
                    <th scope="col">
                      <small>{formatNum(objTotal.pending)}</small>
                    </th>
                    <th scope="col">
                      <small>{formatNum(objTotal.paid)}</small>
                    </th>
                    <th scope="col">
                      <small>{formatNum(objTotal.total)}</small>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row ">
        <div class="col-12">
          <div
            class="btn-group btn-group"
            role="group"
            aria-label="Basic example"
            style={{
              fontSize: "0.8em",
              width: "100%"
            }}
          >
            {_.map(list, l => (
              <button
                type="button"
                style={{
                  // fontSize : '0.8em',
                  backgroundColor: "#E9ECEF",
                  color: "#495057",
                  borderColor: "rgb(206, 212, 218)"
                }}
                class={`btn btn-secondary ${modeGraph === l.mode ? 'active focus' : ''}`}
                onClick={e => {
                  chageModeGraph(l.mode);
                }}
              >
                {l.name}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div class="row py-2">
        <div class="col-12">
          <CardGraph options={options} />
        </div>
      </div>
    </div>
  );
};
export default LivePageAll;
