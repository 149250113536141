import React from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

const ButtonBack = props => {
  let { appState, shopList } = useSelector(state => {
    return {
      appState: state.appState,
      shopList: state.shopList
    };
  });
  const { history } = props;
  function clickBack() {
    console.log("============clickBack======================");
    console.log();
    console.log("=============clickBack====================");

    history.goBack();
  }

  return (
    <button type="button" class="btn btn-sm btn-primary btn-block" onClick={clickBack}>
      <i class="fas fa-arrow-left" style={{paddingRight:8}} ></i>
      back
    </button>
  );
  
};
export default ButtonBack;
