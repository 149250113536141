import React, { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import formatNum from "format-num";
import {listTherapistName,getTimeStamp} from '../data/funcGlobal'


const CardNPL = props => {
  const { items } = props;
  const date = items.timestamp.split("#")[1] || "";

  const [objAnswers, setObjAnswers] = useState({});
  const [objDef, setObjDef] = useState({});
  const nameTherapist = _.map(items.therapist, course => {
    return _.map(course.therapists, t => {});
  });

  useEffect(() => {
    mapData();
  }, [items]);

  function mapData() {
    if (items.satRate) {
      let newAwns = {};
      newAwns = _.reduce(
        items.satRate.data,
        (acc, a, key) => {
          acc[a.name] = {
            ...a
          };
          return acc;
        },
        {}
      );

      let definition = {};

      const arr = items.satRate.data;
      let newDef = {};
      newDef = _.reduce(
        arr,
        (acc, f, key) => {
          acc[f.name] = f.name;
          return acc;
        },
        {}
      );
      setObjAnswers(newAwns);
      setObjDef(newDef);
    }
  }
  return (
    <div class="col-md-12">
      <div class="card shadow">
        <div
          class="card-body"
          style={{ paddingLeft: "8px", paddingRight: "8px" }}
        >
          <div class="row no-gutters">
            <div class="col-8">
              <p>
                <i
                  class="fas fa-calendar-alt"
                  style={{ color: "grey", paddingRight: 8 }}
                ></i>
                <small>
                  <strong>{moment(date).format("HH:mm")}</strong>
                  {"   " + moment(date).format("DD MMM YYYY")}
                </small>
              </p>
            </div>
            <div class="col-4 text-right pr-1">
              <p>
                <small>
                  <strong>{formatNum(items.payment.grandTotal)}</strong>
                </small>
              </p>
            </div>
            <div class="col-6">
              <p>
                <i
                  class="fas fa-user"
                  style={{ color: "grey", fontSize: 14, paddingRight: 8 }}
                ></i>
                <small>
                  <strong>{items.member.name}</strong>
                </small>
              </p>
            </div>
            <div class="col-6 text-right pr-1">
              <p>
                <small>
                  <em style={{ color: "grey" }}>
                    {items.therapist
                      ? "by  " + listTherapistName(items.therapist)
                      : ""}
                  </em>{" "}
                </small>
              </p>
            </div>
          </div>
          {items.items ? (
            <div class="row card-text">
              <div class="col-12">
                <p>
                  {/* <i
                    class="fas fa-archive mr-1"
                    style={{ color: "grey", fontSize: 14 }}
                  ></i> */}
                  <small>
                    {/* <em style={{color:'grey'}}> */}
                    {_.map(items.items, (i, key) => {
                      return [
                        <i
                          class="fas fa-archive mr-1"
                          style={{
                            color: "grey",
                            fontSize: 14,
                            visibility: `${key != 0 ? "hidden" : ""}`
                          }}
                          key={key}
                        ></i>,
                        <span>
                          <em style={{ color: "grey" }}>
                            <b>{i.qty} X </b> {i.name}
                          </em>
                        </span>,
                        <br />
                      ];
                    })}

                    {/* </em> */}
                  </small>
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
          {_.map(objAnswers, anw => {
            if (anw.type === "string" && anw.value) {
              return (
                <div class="row card-text">
                  <div class="col-12">
                    <p>
                      <i
                        class="fas fa-comment-dots"
                        style={{ color: "grey", fontSize: 14, paddingRight: 8 }}
                      ></i>
                      <small>
                        <em style={{ color: "blue" }}>{anw.value || ""}</em>
                      </small>
                    </p>
                  </div>
                </div>
              );
            }
          })}

          <div class="row card-text mt-1">
            {_.map(objAnswers, anw => {
              if (anw.type === "rating") {
                return (
                  <div class="col-4 pr-0">
                    <div style={{ marginTop: -4, marginBottom: -5 }}>
                      {_.times(anw.value, function() {
                        return (
                          <i
                            class="fas fa-star"
                            style={{ color: "#f2b01e", fontSize: 14 }}
                          ></i>
                        );
                      })}
                      {_.times(5 - anw.value, function() {
                        return (
                          <i
                            class="fas fa-star"
                            style={{ color: "#E9ECEF", fontSize: 14 }}
                          ></i>
                        );
                      })}
                    </div>

                    <small className="abc" style={{ fontSize: 12 }}>
                      {anw.name}
                    </small>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <div class="col-md-12">
      <div class="card shadow">
        <div
          class="card-body"
          style={{ paddingLeft: "8px", paddingRight: "8px" }}
        >
          <div class="row no-gutters">
            <div class="col-8">
              <p>
                <i
                  class="fas fa-calendar-alt"
                  style={{ color: "grey", paddingRight: 8 }}
                ></i>
                <small>
                  <strong>{moment(items.timestamp).format("HH:mm")}</strong>
                  {"   " + moment(items.timestamp).format("DD MMM YYYY")}
                </small>
              </p>
            </div>
            <div class="col-4 text-right pr-1">
              <p>
                <small>
                  <strong>{formatNum(items.payment.grandTotal)}</strong>
                </small>
              </p>
            </div>
            <div class="col-6">
              <p>
                <i
                  class="fas fa-user"
                  style={{ color: "grey", fontSize: 14, paddingRight: 8 }}
                ></i>
                <small>
                  <strong>{items.member.name}</strong>
                </small>
              </p>
            </div>
            <div class="col-6 text-right pr-1">
              <p>
                <small>
                  {items.therapist && items.therapist.name && (
                    <em style={{ color: "grey" }}>
                      {"by " + items.therapist.name}
                    </em>
                  )}
                </small>
              </p>
            </div>
          </div>
          {items.items ? (
            <div class="row card-text">
              <div class="col-12">
                <p>
                  {/* <i
                    class="fas fa-archive mr-1"
                    style={{ color: "grey", fontSize: 14 }}
                  ></i> */}
                  <small>
                    {/* <em style={{color:'grey'}}> */}
                    {_.map(items.items, (i, key) => {
                      return [
                        <i
                          class="fas fa-archive mr-1"
                          style={{
                            color: "grey",
                            fontSize: 14,
                            visibility: `${key != 0 ? "hidden" : ""}`
                          }}
                          key={key}
                        ></i>,
                        <span>
                          <em style={{ color: "grey" }}>
                            <b>{i.qty} X </b> {i.name}
                          </em>
                        </span>,
                        <br />
                      ];
                    })}

                    {/* </em> */}
                  </small>
                </p>
              </div>
            </div>
          ) : (
            ""
          )}

          {objAnswers["suggestion"] ? (
            <div class="row card-text">
              <div class="col-12">
                <p>
                  <i
                    class="fas fa-comment-dots"
                    style={{ color: "grey", fontSize: 14, paddingRight: 8 }}
                  ></i>
                  <small>
                    <em style={{ color: "blue" }}>
                      {objAnswers["suggestion"]}
                    </em>
                  </small>
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
          <div class="row card-text mt-1">
            <div class="col-4 pr-0">
              <div style={{ marginTop: -4, marginBottom: -5 }}>
                {_.times(objAnswers["overall"], function() {
                  return (
                    <i
                      class="fas fa-star"
                      style={{ color: "#f2b01e", fontSize: 14 }}
                    ></i>
                  );
                })}
                {objAnswers["overall"] < 5 &&
                  _.times(5 - objAnswers["overall"], function() {
                    return (
                      <i
                        class="fas fa-star"
                        style={{ color: "#E9ECEF", fontSize: 14 }}
                      ></i>
                    );
                  })}
              </div>

              <small className="abc" style={{ fontSize: 12 }}>
                {objDef["overall"] ? "Overall" : ""}
              </small>
            </div>
            <div class="col-4 pr-0 pl-0">
              <div style={{ marginTop: -4, marginBottom: -5 }}>
                {_.times(objAnswers["program"], function() {
                  return (
                    <i
                      class="fas fa-star"
                      style={{ color: "#f2b01e", fontSize: 14 }}
                    ></i>
                  );
                })}
                {objAnswers["program"] < 5 &&
                  _.times(5 - objAnswers["program"], function() {
                    return (
                      <i
                        class="fas fa-star"
                        style={{ color: "#E9ECEF", fontSize: 14 }}
                      ></i>
                    );
                  })}
              </div>

              <small className="abc" style={{ fontSize: 12 }}>
                {objDef["program"] ? "Program/Treatment" : ""}
              </small>
            </div>
            <div class="col-4 pr-0 ">
              <div style={{ marginTop: -4, marginBottom: -5 }}>
                {_.times(objAnswers["cleanliness"], function() {
                  return (
                    <i
                      class="fas fa-star"
                      style={{ color: "#f2b01e", fontSize: 14 }}
                    ></i>
                  );
                })}
                {objAnswers["cleanliness"] < 5 &&
                  _.times(5 - objAnswers["cleanliness"], function() {
                    return (
                      <i
                        class="fas fa-star"
                        style={{ color: "#E9ECEF", fontSize: 14 }}
                      ></i>
                    );
                  })}
              </div>

              <small className="abc" style={{ fontSize: 12 }}>
                {objDef["cleanliness"]}
              </small>
            </div>
            <div class="col-4 pr-0 mt-1">
              <div style={{ marginTop: -4, marginBottom: -5 }}>
                {_.times(objAnswers["ambience"], function() {
                  return (
                    <i
                      class="fas fa-star"
                      style={{ color: "#f2b01e", fontSize: 14 }}
                    ></i>
                  );
                })}
                {objAnswers["ambience"] < 5 &&
                  _.times(5 - objAnswers["ambience"], function() {
                    return (
                      <i
                        class="fas fa-star"
                        style={{ color: "#E9ECEF", fontSize: 14 }}
                      ></i>
                    );
                  })}
              </div>

              <small className="abc" style={{ fontSize: 12 }}>
                {objDef["ambience"] ? "Ambience" : ""}
              </small>
            </div>
            <div class="col-4 pr-0  pl-0 mt-1">
              <div style={{ marginTop: -4, marginBottom: -5 }}>
                {_.times(objAnswers["receptionist"], function() {
                  return (
                    <i
                      class="fas fa-star"
                      style={{ color: "#f2b01e", fontSize: 14 }}
                    ></i>
                  );
                })}
                {objAnswers["receptionist"] < 5 &&
                  _.times(5 - objAnswers["receptionist"], function() {
                    return (
                      <i
                        class="fas fa-star"
                        style={{ color: "#E9ECEF", fontSize: 14 }}
                      ></i>
                    );
                  })}
              </div>

              <small className="abc" style={{ fontSize: 12 }}>
                {objDef["receptionist"] ? "Receptionist" : ""}
              </small>
            </div>
            <div class="col-4 pr-0 mt-1">
              <div style={{ marginTop: -4, marginBottom: -5 }}>
                {_.times(objAnswers["therapist"], function() {
                  return (
                    <i
                      class="fas fa-star"
                      style={{ color: "#f2b01e", fontSize: 14 }}
                    ></i>
                  );
                })}
                {objAnswers["therapist"] < 5 &&
                  _.times(5 - objAnswers["therapist"], function() {
                    return (
                      <i
                        class="fas fa-star"
                        style={{ color: "#E9ECEF", fontSize: 14 }}
                      ></i>
                    );
                  })}
              </div>

              <small className="abc" style={{ fontSize: 12 }}>
                {objDef["therapist"] ? "Therapist" : ""}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardNPL;

// sheet_id='1NnxsPSDkj0t6Xh-2Yev2P4CL8IesO2WnOlziatijFDU'
// const API = "AIzaSyACJ3nFnsEcxogpnEFAE63OAdi6yTBHSkk";

//------
