import React from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useEffect } from "react";
import moment from "moment";
import { cognitoSignOut } from "../data/cognito";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  withRouter
} from "react-router-dom";

const NavEnchanced = props => {
  let { appState } = useSelector(state => {
    return {
      appState: state.appState
    };
  });

  let { branchName } = appState;
  const dispatch = useDispatch();
  const { history } = props;

  function gotoSelectBranch() {
    dispatch({
      type: "SET_APPSTATE",
      key: "mode",
      payload: null
    });
    dispatch({
      type: "SET_APPSTATE",
      key: "branchName",
      payload: null
    });
    history.push("/");
  }
  return (
    <nav>
    <nav class="navbar navbar-expand-lg  navbar-dark bg-primary">
      {branchName && (
        <a
          class="navbar-brand"
          href="#"
          onClick={e => {
            gotoSelectBranch();
          }}
        >
          <i class="fas fa-home"></i>

          <div class="LineVertical"></div>
          <small style={{ paddingLeft: 30 }}>{branchName}</small>
        </a>
      )}
      {!branchName && (
        <a
          class="navbar-brand"
          href="#"
          onClick={e => {
            gotoSelectBranch();
          }}
        >
          Divana's Spa
        </a>
      )}
      <a class="navbar-brand" href="#" onClick={() => history.push("/")}>
        {/* <span style={{margin:0}}>{branchName}</span> */}
      </a>
      <button
        onClick={() => history.push("/")}
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item row justify-content-end">
            <button
              class="btn btn-outline-info btn-sm mt-3"
              id="logout"
              href="#"
              style={{ marginRight: "15px" }}
              onClick={e => {
                cognitoSignOut();
              }}
            >
              Logout
            </button>
          </li>
        </ul>
      </div>
    </nav>
    
    </nav>
  );
};

export default withRouter(NavEnchanced);
