import React, { useState } from "react";
import "../App.css";
import { cognitoSignOut } from "../data/cognito";
import { withRouter } from "react-router-dom";
const TabBranch = props => {
  const { history } = props;
  const [tabname, setTabname] = useState("LiveSales");
  function clickTab(patch, tabname) {
    history.push(patch);
    setTabname(tabname);
  }
  function clickLogout() {
    cognitoSignOut();
  }

  return (
    <ul
      class="nav mb-3"
      style={{
        flexWrap: "nowrap",
        overflowX: "auto",
        height: 48,
        borderBottom: "1px solid #e3e3e3"
      }}
    >
      <li class="nav-item">
        <a
          class={`nav-link ${tabname === "LiveSales" ? "active" : ""}`}
          href="#"
          onClick={e => {
            clickTab("/LivePageBranch", "LiveSales");
          }}
        >
          LiveSales
        </a>
      </li>

      <li class="nav-item">
        <a
          class={`nav-link ${tabname === "Summary" ? "active" : ""}`}
          href="#"
          onClick={e => {
            clickTab("/SummaryPageBranch", "Summary");
          }}
        >
          Summary
        </a>
      </li>

      <li class="nav-item">
        <a
          class={`nav-link ${tabname === "Receipts" ? "active" : ""}`}
          href="#"
          onClick={e => {
            clickTab("/Receipts", "Receipts");
          }}
        >
          Receipts
        </a>
      </li>

      <li class="nav-item">
        <a
          class={`nav-link ${tabname === "Products" ? "active" : ""}`}
          href="#"
          onClick={e => {
            clickTab("/ProductReport", "Products");
          }}
        >
          Products
        </a>
      </li>
      <li class="nav-item">
        <a
          class={`nav-link ${tabname === "Voids" ? "active" : ""}`}
          href="#"
          onClick={e => {
            clickTab("/Voids", "Voids");
          }}
        >
          Voids
        </a>
      </li>

      <li class="nav-item">
        <a
          class={`nav-link ${tabname === "Therapist" ? "active" : ""}`}
          href="#"
          onClick={e => {
            clickTab("/Therapist", "Therapist");
          }}
        >
          Therapist
        </a>
      </li>

      <li class="nav-item">
        <a
          class={`nav-link ${tabname === "SatRate" ? "active" : ""}`}
          href="#"
          onClick={e => {
            clickTab("/SatRate", "SatRate");
          }}
        >
          SatRate
        </a>
      </li>

      <li class="nav-item">
        <a
          class={`nav-link ${tabname === "Entertain" ? "active" : ""}`}
          href="#"
          onClick={e => {
            clickTab("/EntertainSummary", "Entertain");
          }}
        >
          Entertain
        </a>
      </li>

      <li class="nav-item">
        <a
          class={`nav-link ${tabname === "Agent" ? "active" : ""}`}
          href="#"
          onClick={e => {
            clickTab("/AgentSummary", "Agent");
          }}
        >
          Agent
        </a>
      </li>
      <li class="nav-item">
        <a
          class={`nav-link ${tabname === "Channels" ? "active" : ""}`}
          href="#"
          onClick={e => {
            clickTab("/Channels", "Channels");
          }}
        >
          Channels
        </a>
      </li>
      <li class="nav-item">
        <a
          class={`nav-link ${tabname === "Nations" ? "active" : ""}`}
          href="#"
          onClick={e => {
            clickTab("/Nations", "Nations");
          }}
        >
          Nations
        </a>
      </li>
    </ul>
  );

  return (
    // <nav class="nav flex-row" style={{overflow: 'auto'}}>
    //             <a class="nav-link active text-white" onClick={e=>{clickTab('/')}}>Live</a>
    //           <a class="nav-link active text-white" onClick={e=>{clickTab('/SummaryPageBranch')}}>Summary</a>
    //           <a class="nav-link active text-white" onClick={e=>{clickTab('/Receipts')}}>Receipts</a>
    //           <a class="nav-link active text-white" onClick={e=>{clickLogout()}}>Logout</a>
    //           <a class="nav-link active text-white" onClick={e=>{clickLogout()}}>Logout</a>
    //           <a class="nav-link active text-white" onClick={e=>{clickLogout()}}>Logout</a>
    //           <a class="nav-link active text-white" onClick={e=>{clickLogout()}}>Logout</a>
    // </nav>
    ///====
    <div>
      <a
        class="tabA"
        onClick={e => {
          clickTab("/LivePageBranch");
        }}
      >
        Live
      </a>
      <a
        onClick={e => {
          clickTab("/SummaryPageBranch");
        }}
      >
        Summary
      </a>

      <a
        onClick={e => {
          clickTab("/Receipts");
        }}
      >
        Receipts
      </a>
      <a
        onClick={e => {
          clickTab("/SatRate");
        }}
      >
        SatRate
      </a>
      <a
        onClick={e => {
          clickTab("/Therapist");
        }}
      >
        Therapist
      </a>
      <a
        onClick={e => {
          clickTab("/AgentSummary");
        }}
      >
        Agent
      </a>
      <a
        onClick={e => {
          clickTab("/EntertainSummary");
        }}
      >
        Entertain
      </a>
      <a
        onClick={e => {
          clickTab("/ProductReport");
        }}
      >
        ProductReport
      </a>
    </div>
    ///====
  );
};
export default withRouter(TabBranch);
