


import React, { useState, useEffect } from "react";


const LivePageBranch = (props) => {
    const {show} =props
  
    return (
        <div class="row">
            <div class="col-12">
            <div class="alert alert-light" role="alert">
                <h4 class="alert-heading">No Data</h4>
                <p>
                This data cannot be found.
                </p>
                
              </div>
            </div>
        </div>
      );
};
export default LivePageBranch;
