import React, { useState,useEffect } from "react";
import _ from "lodash";
import * as Dynamo from '../data/dynamoDb'
import BodyRateReport from '../BodyRateReport'
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import moment from 'moment';
 
import "react-datepicker/dist/react-datepicker.css"
import TableHeader from "../Component/DateRange";
import {fetchDataReceipts,setLoading} from '../data/actions'




const PageUserRateReport = props => {




  let{receipts_fetched,appState} = useSelector(state=>{
    return{ 
      receipts_fetched: state.receipts_fetched,
      appState:state.appState
    }
  })
   
  const {queryDate,queryMonth,startDate,endDate,branchSelected,mode} = appState
  
  const dispatch = useDispatch()
  const [page] = useState(0);
  const [date,setDate] = useState(new Date())
  
  
  const [data,setData] = useState([]);

  

 
  useEffect(() => {    
    fetchData()
  }, [startDate,endDate]);

  
  
  async function fetchData (){
    dispatch(setLoading(true))

    let options = {
      dateRange:{
        startDate:startDate,
        endDate:endDate
      }
    }
    dispatch(await fetchDataReceipts(branchSelected,options))

  }
  
  
  
  return (
    <div className="App">
      <div class="row">
        <div class="col-12">
          <TableHeader />
        </div>
        {page === 0 && <div class="col-12">
        <BodyRateReport data={receipts_fetched} />
        </div>}
      </div>
         
      
    </div>
  );
 
};

export default PageUserRateReport;












