import firebase from "firebase";
import "firebase/firestore";

var firebaseConfig = {
    apiKey: "AIzaSyDrPLVj4PRDjKb6mrQIVufeCmB39AQTngY",
    authDomain: "divana-project.firebaseapp.com",
    databaseURL: "https://divana-project.firebaseio.com",
    projectId: "divana-project",
    storageBucket: "divana-project.appspot.com",
    messagingSenderId: "96393886787",
    appId: "1:96393886787:web:1b2cf109f2073e9c"
  };
  //
 firebase.initializeApp(firebaseConfig);
export const rootRef = firebase.database().ref();
export const notificationRef = rootRef.child("inbox_lastupdate");
export const spaPosTimeStampRef = rootRef.child("spaPosTimeStamp");

//firestore
export let fireStoreRootRef = firebase.firestore();
export let globalMessageRef = fireStoreRootRef.collection("global_messages"); //.doc("wizzy")
export let alertMessageRef = fireStoreRootRef.collection("alert_messages");
