import moment from 'moment'
import {dynamoDbClient} from '../data/dynamoDb'

export async function fecthSummaryChart(uid,startDate,endDate) {
    var params = {
      TableName: "Spa_LiveScore",
      KeyConditionExpression:
        "storeUid = :storeUid AND businessDate BETWEEN :startDate AND :endDate",
      ExpressionAttributeValues: {
        ":storeUid": uid,
        ":startDate":startDate,
        ":endDate":endDate
      }
    };
  
    let res = await dynamoDbClient.query(params).promise();
    
  
    const dateList = res.Items;
    return dateList
  
    
  }