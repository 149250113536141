import moment from "moment";

var init = {
  user: null,
  store: null,

  appState: {
    branchName: null,
    loading: false,
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    branchSelected: "all",
    mode: null, // 'all' || 'branch'
    queryDate: moment().format("YYYY-MM-DD"),
    queryMonth: moment().format("YYYY-MM"),
    queryYear: moment().format("YYYY"),
    headDetailPage: {},
    queryReceipts: [],
    mode_queryDate: "today"
  },
  shopList: {},
  mounthList: [
    { value: "2019-08", label: "2019-08" },
    { value: "2019-07", label: "2019-07" },
    { value: "2019-06", label: "2019-06" },
    { value: "2019-05", label: "2019-05" }
  ],
  //TODO: เปลี่ยนชื่อ queryReceipts => Receipts_Fetch
  receipts_fetched: [],
  voids_fetch: [] //TODO: เปลี่ยนชื่อ queryVoidReceipts => VoidReceipts_Fetch
};

const reducer = (state = init, action) => {
  switch (action.type) {
    case "SELECT_TAB":
      let newAppState2 = { ...state.appState, [action.key]: action.payload };
      return { ...state, appState: newAppState2 };
    case "ADD":
      console.log("===========ADD======================");
      console.log(action.key);
      console.log(action.payload);
      console.log("====================================");
      return { ...state, [action.key]:action.payload };
    case "SET":
      console.log("===========SET======================");
      console.log(action.key);
      console.log(action.payload);
      console.log("====================================");
      return { ...state, [action.key]: action.payload };
    case "SET_APPSTATE":
      let newAppState = { ...state.appState, [action.key]: action.payload };

      return { ...state, appState: newAppState };
    case "SET_SHOPLIST":
      let newShopList = { ...state.shopList, [action.key]: action.payload };

      return { ...state, appState: newAppState };
    case "SET_SUMMARYOBJ":
      let newSummaryobj = { ...state.summaryObj, [action.key]: action.payload };
      return { ...state, summaryObj: newSummaryobj };
    default:
      return state;
  }
};

export default reducer;
