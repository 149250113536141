

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from 'lodash'
import { useEffect } from "react";
import moment from "moment";



const DatePicker = props => {
  let { appState,  } = useSelector(state => {
    return {
      appState: state.appState,
    };
  });
  const { queryDate} = appState;
  const {changeDate,fetchData,hidden} = props


  useEffect(() => {
    
    let $date = window.$(".date").pickadate({
      onSet: function(thingSet) {
        ;
        console.log("Set stuff:", thingSet);
        if (thingSet.select) {
          let ePoch = thingSet.select;
          let newDate = moment(ePoch).format("YYYY-MM-DD");
          changeDate(newDate);
          // fetchData(newDate);
        }
      }
    });
    // Use the picker object directly.
    var picker = $date.pickadate("picker");
    picker.set("select", queryDate, { format: "yyyy-mm-dd" });
    // fetchData(queryDate);
  }, []);

  

  return (
        <div class="input-group mb-3">
            <input
              type="text"
              class="form-control date"
              placeholder="Recipient's username"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
            />
            <div class="input-group-append" hidden={hidden?hidden:false}>
              <span
                class="input-group-text"
                onClick={e => {
                  fetchData(queryDate);
                }}
                id="basic-addon2"
              >
                Submit
              </span>
            </div>
          </div>
  );

  
};
export default DatePicker;
