import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import CardTableGlobal from "../Component/CardTableGlobal";
import { timeConvert } from "../data/funcGlobal";
import TableHeader from "../Component/DateRange";
import formatNum from "format-num";
import AlertNodata from "../Component/AlertNodata";
import { fetchDataReceipts } from "../data/actions";
import {setLoading} from '../data/actions'

const TherapistSummaryPageBranch = props => {
  let { appState, receipts_fetched } = useSelector(state => {
    return {
      appState: state.appState,
      receipts_fetched: state.receipts_fetched
    };
  });
  const dispatch = useDispatch();
  const { branchSelected, startDate, endDate } = appState;
  const [queryMode, setqueryMode] = useState("date");
  const [listHeader, setListHeader] = useState(["Name", "Time"]);
  const [listData, setListData] = useState([]);
  const [data, setData] = useState([]);
  const [receipts, setReceipts] = useState([]);
  const [sumTotal, setSumtotal] = useState(0);

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  useEffect(() => {
    computedData(receipts_fetched);
  }, [receipts_fetched]);
  function clickRow(value) {
    dispatch({
      type: "SET_APPSTATE",
      key: "querySelected",
      payload: value
    });
    props.history.push("/Therapist/Details");
  }

  async function fetchData() {
    dispatch(setLoading(true))
    let options = {
      dateRange: {
        startDate: appState.startDate,
        endDate: appState.endDate
      }
    };

    dispatch(await fetchDataReceipts(branchSelected, options));
  }

  function computedData(receipts) {
    let obj_therapist = {};
    // obj_therapist = _.reduce(
    //   receipts,
    //   (acc, d, key) => {

    //     if (d.therapist) {
    //       if (acc[d.therapist.uid] === undefined) {
    //         acc[d.therapist.uid] = {
    //           name: d.therapist.name,
    //           uid:d.therapist.uid,
    //           timeMinutes: 0,
    //           grandTotal: 0
    //         };
    //       }
    //       if (acc[d.therapist.uid]) {
    //         acc[d.therapist.uid].grandTotal += d.payment.grandTotal;

    //         _.forEach(d.items, item => {
    //           if (item.timeMinutes) {
    //             acc[d.therapist.uid].timeMinutes += item.timeMinutes;
    //           }
    //         });
    //       }
    //     }
    //     return acc;
    //   },
    //   {}
    // );\
    let sum = 0;
    _.forEach(receipts, r => {
      _.forEach(r.therapist, course => {
        _.forEach(course.therapists, t => {
          if (obj_therapist[t.uid] === undefined) {
            obj_therapist[t.uid] = {
              ...t,
              timeMinutes: 0,
              grandTotal: 0
            };
          }
          obj_therapist[t.uid]["timeMinutes"] +=
            obj_therapist[t.uid]["workMinutes"];
        });
      });
      sum += (r.payment && r.payment.grandTotal) || 0;
    });

    let arr_therapist = [];
    _.forEach(obj_therapist, o => {
      arr_therapist.push({
        uid: o.uid,
        att1: o.name,
        time: "(" + timeConvert(o.timeMinutes) + ")"
        // att3: o.grandTotal
      });
    });
    let arr_therapist_orderby = _.orderBy(arr_therapist, "att3", "desc");
    setListData(arr_therapist_orderby);
    setSumtotal(sum);
    dispatch(setLoading(false))

  }

  return (
    <div className="App">
      <div class="row ">
        <div class="col-12">
          <TableHeader />
        </div>
        {listData.length < 1 && (
          <div class="col-12 mt-5">
            <AlertNodata />
          </div>
        )}

        {listData.length >= 1 && (
          <div class="col-12 mt-2">
            <p class="mt-2 mb-3">
              <span>จำนวนบิล {listData.length} บิล</span>
              <span style={{ float: "right" }}>
                ยอดรวม <strong>{formatNum(sumTotal)}</strong> บาท
              </span>
            </p>
            <CardTableGlobal
              HeadCard={"Therapist"}
              listHeader={listHeader}
              listData={listData}
              clickRow={clickRow}
            />
          </div>
        )}
      </div>

      <div class="row"></div>
    </div>
  );
};

export default TherapistSummaryPageBranch;
