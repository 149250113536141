import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import _ from "lodash";
import formatNum from "format-num";
import CardMain from "../Component/CardMain";
import CardTable from "../Component/CardTable";
import CardGraph from "../Component/CardGraph";
import { fetchDataReceipts } from "../data/actions";
import { fecthSummaryChart } from "../service/fecthSummaryChart";
import {setLoading} from '../data/actions'


const SummaryPageBranch = () => {
  const [date, setDate] = useState(new Date());
  const [mode, setMode] = useState("month");
  const [data, setData] = useState([]);
  const [listDate, setListDate] = useState([]);
  const [options, setOption] = useState(null);
  const [grandTotal, setGrandTotal] = useState(0);
  const [paymentType, setPaymentType] = useState({});
  const [listMonth, setListMonth] = useState([]);

  let {
    shopInfo,
    appState,
    mounthList,
    receipts_fetched,
    yearList
  } = useSelector(state => {
    return {
      shopInfo: state.shopInfo,
      appState: state.appState,
      mounthList: state.mounthList,
      receipts_fetched: state.receipts_fetched,
      yearList: state.yearList
    };
  });

  const { queryDate, queryMonth, queryYear, branchSelected } = appState;

  const dispatch = useDispatch();

  // useEffect(() => {
  //   generateDate();
  //   fetchAnalytic(queryMonth);
  // }, [branchSelected]);
  useEffect(() => {
    // generateDate();
  }, [mode]);

  // useEffect(() => {
  //   mapDataToOptionChart(receipts_fetched);
  // }, [receipts_fetched]);

  useEffect(() => {
    processDataToChart();
  }, [queryMonth, mode]);
  useEffect(() => {
    mapDataToOptionChart();
  }, [listDate]);

  function mapDataToOptionChart(data = []) {
    let newData = [];
    let totalAll = 0;
    let newPaymentType = {};

  

   
    _.forEach(listDate, d => {
      
     _.forEach(d.summaryPaymentType,type=>{
      if (newPaymentType[type.name] === undefined) {
        newPaymentType[type.name] = 0;
        
      }
      newPaymentType[type.name] += type.amount;
     })
    });

    let listMonthObj = {};
    let newListDate = _.cloneDeep(listDate);

    if (mode === "year") {
      ;
      _.forEach(newListDate, d => {
        let timestamp = moment(d.businessDate).format("YYYY-MM");
        if (listMonthObj[timestamp] === undefined) {
          listMonthObj[timestamp] = {
            date: timestamp,
            businessDate:moment(d.businessDate).format("MMM"),
            grandTotal: 0
          };
        }
        listMonthObj[timestamp]["grandTotal"] += d.grandTotal;
      });
      newListDate = [];
      _.forEach(listMonthObj, obj => {
        newListDate.push({ ...obj });
      });
    }
    ;

    let minWidth = newListDate.length < 7 ? 200 : 1500;
    let newOptions = {
      chart: {
        type: "column",
        scrollablePlotArea: {
          minWidth: minWidth,
          scrollPositionX: 0,
          opacity: 0
        }
      },

      title: {
        text: "Summary"
      },

      xAxis: {
        categories: _.map(newListDate, d => {
          let categories = [d.businessDate];

          return categories;
        }),
        labels: {
          style: {
            fontSize: "16px"
          }
        }
      },
      yAxis: {
        // min: 0,
        title: {
          text: null
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:,.1f}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            rotation: -90,
            color: "white",
            borderColor: "none",
            align: "right",
            // format: '{point.y:.1f}', // one decimal
            y: 10, // 10 pixels down from the top
            style: {
              fontSize: "auto",
              fontFamily: "Verdana, sans-serif",
              textOutline: "0px"
            }
          }
        }
      },
      series: [
        {
          name: "grandtotal",
          data: _.map(newListDate, d => {
            let data = [d.grandTotal];
            totalAll += d.grandTotal;
            return data;
          })
        }
      ]
    };
    setOption(newOptions);
    setGrandTotal(totalAll);
    setPaymentType(newPaymentType);
    dispatch(setLoading(false))


    
  }
 

  function changeMounth(date) {
    dispatch({
      type: "SET_APPSTATE",
      key: "queryMonth",
      payload: date
    });
    fetchAnalytic(date);
  }
  function changeYear(value) {
    dispatch({
      type: "SET_APPSTATE",
      key: "queryYear",
      payload: value
    });
    fetchAnalytic(value);
  }

  function changeMode(value) {
    let newDate = value === "month" ? queryMonth : queryYear;

    setMode(value);
    fetchAnalytic(newDate);
  }

  async function fetchAnalytic(date) {
    let options = {
      timestamp: date
    };
    dispatch(await fetchDataReceipts(branchSelected, options));
  }
  async function processDataToChart() {
    dispatch(setLoading(true))
    let uid = branchSelected;
    let startDate = null;
    let endDate = null;
    if (mode === "month") {
      startDate = queryMonth;
      endDate = queryMonth + "zzzzzzzzzzzzzzz";
    }
    if (mode === "year") {
      startDate = queryYear;
      endDate = queryYear + "zzzzzzzzzzzzzzz";
    }
    ;
    let dateList = await fecthSummaryChart(uid, startDate, endDate);
    
    
    
    setListDate(dateList);
  }

  return (
    <div className="SummaryPageBranch">
      <div class="row no-gutters">
        {mode === "month" && (
          <div class="col-6">
            <div class="input-group mb-3">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                value={queryMonth}
                onChange={e => {
                  changeMounth(e.target.value);
                }}
              >
                {mounthList.map(m => {
                  return <option value={m.value}>{m.label}</option>;
                })}
              </select>
            </div>
          </div>
        )}
        {mode === "year" && (
          <div class="col-6">
            <div class="input-group mb-3">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                value={queryYear}
                onChange={e => {
                  changeYear(e.target.value);
                }}
              >
                {_.map(yearList, y => {
                  return <option value={y.value}>{y.label}</option>;
                })}
              </select>
              {/*<div class="input-group-append">
                <button
                  class="btn  btn-primary"
                  type="button"
                  id="button-addon2"
                >
                  Go
                </button>
        </div>*/}
            </div>
          </div>
        )}

        <div class="col-6 pl-2">
          <div
            style={{ width: "100%" }}
            class="btn-group btn-group-toggle"
            data-toggle="buttons"
          >
            <label
              class={`btn  btn-secondary ${
                mode === "month" ? "active focus" : ""
              }`}
              onClick={e => {
                changeMode("month");
              }}
              style={{
                backgroundColor: "#E9ECEF",
                color: "#495057",
                borderColor: "rgb(206, 212, 218)"
              }}
            >
              <input
                type="radio"
                name="options"
                id="option2"
                autocomplete="off"
              />{" "}
              Mounth
            </label>
            <label
              class={`btn  btn-secondary ${
                mode === "year" ? "active focus" : ""
              }`}
              onClick={e => {
                changeMode("year");
              }}
              style={{
                backgroundColor: "#E9ECEF",
                color: "#495057",
                borderColor: "rgb(206, 212, 218)"
              }}
            >
              <input
                type="radio"
                name="options"
                id="option3"
                autocomplete="off"
              />{" "}
              Year
            </label>
          </div>
        </div>
      </div>
      <div class="row py-1 mb-5">
        <div class="col-12">
          <CardMain value1={formatNum(grandTotal)} title1={"GrandTotal"} />
        </div>
      </div>

      <CardGraph id="grapScroll" options={options} />

      <div class="row  mt-5">
        <div class="col-12">
          <CardTable paymentType={paymentType} />
        </div>
      </div>
    </div>
  );
};
export default SummaryPageBranch;

const getDateListDesc = month => {
  const startOfMonth = moment(month).startOf("month");
  const endOfMonth = moment(month).endOf("month");

  let diff = endOfMonth.diff(startOfMonth, "days") + 1;
  if (month === moment().format("YYYY-MM")) {
    //ถ้าเดือนปัจจุบัน เอาแค่ถึงวันที่ปัจจุบัน
    diff = moment().diff(startOfMonth, "days") + 1;
  }

  let datesOfMonth = [];

  _.times(diff, index => {
    datesOfMonth.push(
      moment(month)
        .startOf("month")
        .add(index, "days")
        .format("YYYY-MM-DD")
    );
  });
  return datesOfMonth;
};
const getMonthListDesc = month => {
  const startOfMonth = moment(month).startOf("year");
  const endOfMonth = moment(month).endOf("year");

  let diff = endOfMonth.diff(startOfMonth, "month") + 1;
  if (month === moment().format("YYYY")) {
    //ถ้าเดือนปัจจุบัน เอาแค่ถึงวันที่ปัจจุบัน
    diff = moment().diff(startOfMonth, "month") + 1;
  }

  let datesOfMonth = [];

  _.times(diff, index => {
    datesOfMonth.push(
      moment(month)
        .startOf("month")
        .add(index, "month")
        .format("YYYY-MM")
    );
  });
  return datesOfMonth;
};
const generateMonths = () => {
  let lastYearMonth = moment().subtract(12, "month");
  let monthArrlet = [];
  _.times(13, i => {
    monthArrlet.push({
      label: lastYearMonth.format("MMMM YYYY"),
      value: `${lastYearMonth.format("YYYY-MM")}`
    });
    lastYearMonth.add(1, "month");
  });

  let sortMonthArr = _.orderBy(monthArrlet, "value", "desc");
  return sortMonthArr;
};
