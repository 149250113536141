import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import CardTableGlobal from "../Component/CardTableGlobal";
import TableHeader from "../Component/DateRange";
import { fetchDataReceipts,setLoading } from "../data/actions";
import CardGraph from "../Component/CardGraph";
import AlertNodata from "../Component/AlertNodata";
import Highcharts from "highcharts";
import formatNum from "format-num";

const NationsSummaryPage = props => {
  let { appState, receipts_fetched } = useSelector(state => {
    return {
      appState: state.appState,
      receipts_fetched: state.receipts_fetched
    };
  });
  const dispatch = useDispatch();
  const {
    queryDate,
    queryMonth,
    queryYear,
    branchSelected,
    startDate,
    endDate
  } = appState;
  const [queryMode, setqueryMode] = useState("date");
  const [listHeader, setListHeader] = useState(["Name", "Bills", "GrandTotal"]);
  const [listData, setListData] = useState([]);
  const [data, setData] = useState([]);
  const [optionsGraph, setOptionsGraph] = useState({});
  const [totalBills, setTotalBills] = useState(0);

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);
  useEffect(() => {
    computedData(receipts_fetched);
  }, [receipts_fetched]);

  function clickRow(value) {
    dispatch({
      type: "SET_APPSTATE",
      key: "querySelected",
      payload: value
    });
    props.history.push("/Nations/Details");
  }

  async function fetchData() {
    //   dispatch(setLoading(true))
    let options = {
      dateRange: {
        startDate: appState.startDate,
        endDate: appState.endDate
      }
    };

    dispatch(await fetchDataReceipts(branchSelected, options));
  }

  function computedData(receipts) {
      let objAcc = {}
    let obj_channels_receipts = {};
    let totalBills = 0;
    obj_channels_receipts = _.reduce(
      receipts,
      (acc, r, key) => {
        if (r.member) {
          let m = r.member;
            debugger
          if ( m.country && (acc[m.country] === undefined) ) {
            acc[m.country] = {
              name: m.country,
              bills: 0,
              grandTotal: 0
            };
          }
          if (acc[m.country] !== undefined) {
            acc[m.country].grandTotal += r.payment.grandTotal;
            acc[m.country].bills += 1;
            totalBills += 1;
            debugger;
          }
        }
        return acc;
      },
      objAcc
    );
    let arr_channels = [];
    let b = totalBills;

    _.forEach(obj_channels_receipts, o => {
      if (o.name && o.bills && o.grandTotal) {
        arr_channels.push({
          att1: o.name,
          att2: o.bills,
          att3: o.grandTotal,
          percent: (o.bills / totalBills) * 100
        });
      }
    });

    let arr_chanels_orderby = _.orderBy(arr_channels, "att3", "desc");
    debugger;
    setTotalBills(totalBills);
    setListData(arr_chanels_orderby);
    // dispatch(setLoading(false))

    mapDataToOptionsChart(arr_chanels_orderby);
  }
  function mapDataToOptionsChart(dataGraph) {
    let newOptions = {};
    let pieColors = (function() {
      var colors = [],
        base = Highcharts.getOptions().colors[0],
        i;

      for (i = 0; i < 10; i += 1) {
        // Start out with a darkened base color (negative brighten), and end
        // up with a much brighter color
        colors.push(
          Highcharts.Color(base)
            .brighten((i - 2) / 7)
            .get()
        );
      }
      return colors;
    })();
    newOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie"
      },
      title: {
        text: "Channels"
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          // colors: pieColors,
          dataLabels: {
            enabled: true,
            format: `<b>{point.name}</b><br>{point.percentage:.0f}% ({point.bill})`,
            distance: -50,
            filter: {
              property: "percentage",
              operator: ">",
              value: 4
            }
          }
        }
      },
      series: [
        {
          name: "Share",
          data: mapDataForGraph(dataGraph)
          // name: 'Share',
          // data:  _.map(dataGraph, x => {
          //   let data = [{name : x.att1,y:x.percent}];

          //   return data;
          // })
        }
      ]
    };
    setOptionsGraph(newOptions);
  }
  function mapDataForGraph(dataGraph) {
    let newData = [];
    _.forEach(dataGraph, d => {
      newData.push({
        name: d.att1,
        y: d.percent,
        bill: d.att2
      });
    });

    return newData;
  }
  return (
    <div className="App">
      <div class="row">
        <div class="col-12">
          <TableHeader />
        </div>
        {listData.length < 1 && (
          <div class="col-12 mt-5">
            <AlertNodata />
          </div>
        )}
        {listData.length >= 1 && (
          <div class="col-12">
            <p class="mt-3 mb-3">
              <span>จำนวนบิล {totalBills} บิล</span>
              <span style={{ float: "right" }}>
                ยอดรวม <strong>{formatNum(_.sumBy(listData, "att3"))}</strong>{" "}
                บาท
              </span>
            </p>
            <CardGraph options={optionsGraph} />
          </div>
        )}
        {listData.length >= 1 && (
          <div class="col-12 mt-5">
            <CardTableGlobal
              HeadCard={"Therapist"}
              listHeader={listHeader}
              listData={listData}
              clickRow={clickRow}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default NationsSummaryPage;
