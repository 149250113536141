import React, { useState, useEffect } from "react";
import _ from "lodash";
import * as Dynamo from "../data/dynamoDb";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import CardReceipt from "../Component/CardReceipt";
import ModalReceipt from "../Component/ModalReceipt";
import ButtonBack from '../Component/ButtonBack'

const AgentDetailsPage = props => {
  let { appState, receipts_fetched } = useSelector(state => {
    return {
      appState: state.appState,
      receipts_fetched: state.receipts_fetched
    };
  });

  const dispatch = useDispatch();
  const { querySelected } = appState;
  const [dataModal, setDataModal] = useState({});
  const [dataCard,setDataCard] = useState([])
  debugger


  const [Header, setHeader] = useState({
    att1: "Name",
    att2: "Bills",
    att3: "GranTotal"
  });

  useEffect(() => {
    filter(receipts_fetched);
    
  }, [receipts_fetched]);

  function filter(receipts) {
   let receipts_filter  = _.filter(receipts, r => {
      if (r.agentDetail) {
        return r.agentDetail.payload.uid === querySelected.uid;
      }
      debugger
    });
    let receipts_filter_orderby = _.orderBy(receipts_filter,'timestamp','desc')
    setDataCard(receipts_filter_orderby)
  }
  function clickCard(data) {
    ;
    window.$("#myModal2").modal("show");
    setDataModal(data);
    ;
  }

  return (
    <div className="App">
      <div class="row">
        <div class="col-12" >
          <ButtonBack history={props.history}/>
        </div>
      </div>
     
      <div class="row">
        {_.map(dataCard, r => {
            
          return (
            <div class="col-12 mt-2">
              <CardReceipt items={r}  clickCard={clickCard}/>
            </div>
          );
        })}
      </div>
      <ModalReceipt dataModal={dataModal} />
    </div>
  );
};

export default AgentDetailsPage;
