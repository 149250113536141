import React from "react";
import _ from "lodash";
import CollapesItems from "./CollapsItemsInReceive";

import moment from "moment";
import formatNum from "format-num";
import { listTherapistName, getTimeStamp } from "../data/funcGlobal";

const ModalReceipt = props => {
  const { dataModal } = props;
  debugger;
  let bank =
    (dataModal && dataModal.agentDetail && dataModal.agentDetail.bank && dataModal.agentDetail.bank.bank) ||
    "";
  debugger;

  // const {options = []} = dataModal.payment

  return (
    <div
      class="modal fade"
      id="myModal2"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            {dataModal.member && dataModal.member.name && (
              <h5 class="modal-title" id="exampleModalCenterTitle">
                {dataModal.member.name || ""}
              </h5>
            )}
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-borderless table-sm">
              <tbody>
                {dataModal && dataModal.store && dataModal.store.name && (
                  <tr>
                    <td>
                      <small>branch:</small>
                    </td>
                    <td class="text-right">
                      <small>{dataModal.store.name}</small>
                    </td>
                  </tr>
                )}
                {dataModal &&
                  dataModal.meta &&
                  dataModal.meta.room &&
                  dataModal.meta.bed &&
                  dataModal.meta.room.label &&
                  dataModal.meta.bed.label && (
                    <tr>
                      <td>
                        <small>room:</small>
                      </td>
                      <td class="text-right">
                        <small>
                          {dataModal.meta.room.label} -{" "}
                          {dataModal.meta.bed.label}
                        </small>
                      </td>
                    </tr>
                  )}
                {dataModal && dataModal.receiptId && (
                  <tr>
                    <td>
                      <small>receive id:</small>
                    </td>
                    <td class="text-right">
                      <small>{dataModal.receiptId}</small>
                    </td>
                  </tr>
                )}
                {dataModal && dataModal.timestamp && (
                  <tr>
                    <td>
                      <small>date:</small>
                    </td>
                    <td class="text-right">
                      <small>
                        {moment(getTimeStamp(dataModal.timestamp)).format(
                          "YYYY-MM-DD"
                        )}{" "}
                        {moment(getTimeStamp(dataModal.timestamp)).format(
                          "HH:mm"
                        )}
                      </small>
                    </td>
                  </tr>
                )}

                {dataModal && dataModal.therapist && (
                  <tr>
                    <td>
                      <small>therapis:</small>
                    </td>
                    <td class="text-right">
                      <small>{listTherapistName(dataModal.therapist)}</small>
                    </td>
                  </tr>
                )}
                {dataModal &&
                  dataModal.agentDetail &&
                  dataModal.agentDetail.payload &&
                  dataModal.agentDetail.payload.name && (
                    <tr>
                      <td>
                        <small>agent:</small>
                      </td>
                      <td class="text-right">
                        <small>{dataModal.agentDetail.payload.name}</small>
                      </td>
                    </tr>
                  )}
                {bank && (
                  <tr>
                    <td>
                      <small>bank:</small>
                    </td>
                    <td class="text-right">
                      <small>{bank || ""}</small>
                    </td>
                  </tr>
                )}
                {dataModal && dataModal.entertain && dataModal.entertain.name && (
                  <tr>
                    <td>
                      <small>entertain:</small>
                    </td>
                    <td>
                      <small>{dataModal.entertain.name}</small>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <table class="table table-borderless table-sm">
              <tbody>
                {dataModal &&
                  dataModal.payment &&
                  dataModal.payment.grandTotal && (
                    <tr>
                      <th>
                        GrandTotal:
                        <small>
                          <em>
                            {"(" +
                              _.map(dataModal.payment.paymentTypes, p => {
                                if (p.name !== "") {
                                  let text = `${p.name}`;

                                  return text;
                                }
                              }) +
                              ")"}
                          </em>
                        </small>
                      </th>
                      <th class="text-right">
                        {formatNum(dataModal.payment.grandTotal)}
                      </th>
                    </tr>
                  )}
              </tbody>
            </table>
            <CollapesItems name={"Product"} Items={dataModal.items} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalReceipt;
