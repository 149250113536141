import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './data/store'
import { Provider } from "react-redux";
import LogIn from './Pages/Login'
import { getUser } from "./data/cognito";


let user  = getUser()

var pathName = window.location.pathname;


// if (user) {
//   ReactDOM.render(
//     <Provider store={store}>
//       <App username={user.username} />
//     </Provider>,
//     document.getElementById("root")
//   );
// } else {
//   ReactDOM.render(
//     <Provider store={store}>
//       <LogIn />
//     </Provider>,
//     document.getElementById("root")
//   );
// }


switch (pathName) {
  case "/":
    // CHECK AUTH
    if (user) {
      ReactDOM.render(
        <Provider store={store}>
          <App username={user.username} />
        </Provider>,
        document.getElementById("root")
      );
    } else {
      ReactDOM.render(
        <Provider store={store}>
          <LogIn />
        </Provider>,
        document.getElementById("root")
      );
    }
   
    break;
  default:
    window.location.href = "/";
    break;
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
