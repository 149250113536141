import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const CardGraph = (props) => { 
    Highcharts.setOptions({
		lang: {
			decimalPoint: '.',
            thousandsSep: ','
		}
	});
    const {options} =props
    return<HighchartsReact
    highcharts={Highcharts}
    options={options}
    />
        
    
}
export default CardGraph;