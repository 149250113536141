import {
    CognitoUserPool,
    CognitoUserAttribute,
    CognitoUser,
    AuthenticationDetails
  } from "amazon-cognito-identity-js";
  import AWS from "aws-sdk";
  import swal from "sweetalert";
//   import Swal from 'sweetalert2';
  const poolData = {
    UserPoolId: "ap-southeast-1_cGBlOkVLn",
    ClientId: "7hdaqppemlqpgkacaa115cnmq7"
  };





  const userPool = new CognitoUserPool(poolData);

  export const LogIn = (username, password) => {
    
    var authenticationData = {
      Username: username,
  
      Password: password
    };
    var authenticationDetails = new AuthenticationDetails(authenticationData);
  
    var userData = {
      Username: username,
      Pool: userPool
    };
  
    var cognitoUser = new CognitoUser(userData);
    
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: async function(result) {
        // var accessToken = result.getAccessToken().getJwtToken();
        // window.location.href = "/";
        // swal("เกิดข้อผิดพลาด", "Password ไม่ถูกต้อง", "scu");
        // Swal.fire(
        //     'Login Success',
        //     'You clicked the button!',
        //     'success'
        //   )
        
        window.location.href = "/";
  
  
        
      },
  
      onFailure: function(err) {
        if (err.code === "UserNotFoundException") {
          swal("เกิดข้อผิดพลาด", "ไม่พบบัญชีผู้ใช้ที่คุณกรอก", "error");
          return;
        }
        if (err.code === "NotAuthorizedException") {
          swal("เกิดข้อผิดพลาด", "Password ไม่ถูกต้อง", "error");
          return;
        }
        
  
        alert(err.message);
      }
    });
  };

  export const getUser = () => {
    const userPool = new CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();
    
    return cognitoUser;
   };

export const cognitoSignOut = () => {
    const userPool = new CognitoUserPool(poolData);
    const cognitoUser = userPool.getCurrentUser();
    cognitoUser.signOut();
    window.location.href = "/"
};
   