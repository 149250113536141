import React from 'react'

const Icon = (props) => { 
    const {options} =props
    const {name,colorName} =props


        return (  
            <i class={name} style={{color: colorName || 'grey',paddingRight:8}}>
            </i>
    )
    
}
export default Icon;