import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Dynamo from "../data/dynamoDb";
import moment from "moment";
import _ from "lodash";
import formatNum from "format-num";
import CardLivebranch from "../Component/CardLiveBranch";
import ModalLiveBranch from "../Component/ModalLiveBranch";
import AlertNodata from "../Component/AlertNodata";
import {spaPosTimeStampRef} from '../data/firebaseRef'
import {fetchDataReceipts} from '../data/actions'
import {fetchDataJobs} from '../data/service'
import TableReceipst from '../Component/TableReceipts'

const LivePageBranch = () => {
  const [dataJobs, setDataJobs] = useState([]);
  const [dataReceipts, setDataReceipts] = useState([]);

  const [dataTable, setDataTable] = useState({});
  const [objTotal, setObjTotal] = useState({});
  const [objShopList, setObjShopList] = useState({});
  const [options, setOptions] = useState({});
  const [dataModal, setDataModal] = useState({});
  const [loading, setLoading] = useState(false);
  const [modeData, setModeData] = useState('table');


  let { appState, receipts_fetched, shopList } = useSelector(state => {
    return {
      appState: state.appState,
      receipts_fetched: state.receipts_fetched,
      shopList: state.shopList
    };
  });

  const { branchSelected } = appState;
  const dispatch = useDispatch()
  useEffect(() => {
    const callback = snapshot => {
      fetchData();
    };
    spaPosTimeStampRef.child(branchSelected).on("value", callback);
    return () => {
      spaPosTimeStampRef.child(branchSelected).off("value", callback);
    };
  }, []);

  useEffect(() => {
    createObjShopList();
    fetchData();
  }, [branchSelected]);

  useEffect(() => {
      
      computedData();
    
  }, [dataJobs,receipts_fetched]);

  async function fetchData() {
   
    let date = moment().format("YYYY-MM-DD");

    try {
      let options ={
        timestamp:date
      }
      let resJobs = await fetchDataJobs(branchSelected,options);
      setDataJobs(resJobs);
      options ={
        timestamp:date
      }
      dispatch(await fetchDataReceipts(branchSelected,options))
      // let resRecieve = await fetchDataReceipts(branchSelected,options)
      // let {receipts} = resRecieve
      // dispatch(receipts)
      
    } catch (error) {
      alert(error)
    }
  }
  
  function computedObjJobs(dataJobs){
    let newObjJobs = _.reduce(
      dataJobs,
      (acc, j, key) => {
        if (acc[j.storeUid] === undefined) {
          acc[j.storeUid] = {
            storeUid: j.storeUid,
            pending: 0,
            paid: 0,
            total: 0
          };
        } 
          acc[j.storeUid].pending += j.grandTotal;
        
        return acc;
      },
      {}
    );
    return newObjJobs
  }
  function computedObjStoreUid(objJobs){
    let newObjStoreUid = _.reduce(
      receipts_fetched,
      (acc, r, key) => {
        if (acc[r.storeUid] === undefined) {
          acc[r.storeUid] = {
            storeUid: r.storeUid,
            pending: 0,
            paid: 0,
            total: 0
          };
        }

        if (acc[r.storeUid].paid === undefined) {
          acc[r.storeUid]["paid"] = r.payment.grandTotal;
        } else if (acc[r.storeUid].storeUid === r.storeUid) {
          debugger
          acc[r.storeUid].paid += r && r.payment  && r.payment.grandTotal || 0;
          
          acc[r.storeUid].total =
            acc[r.storeUid].pending + acc[r.storeUid].paid;
        }
        return acc;
      },
      objJobs
    );
    return newObjStoreUid
  }
  async function computedData() {
    
    let objJobs = {};

    objJobs =computedObjJobs(dataJobs)

    let objStoreUid = {};
    objStoreUid = computedObjStoreUid(objJobs)
    

    let objTotal = {
      pending: 0,
      paid: 0,
      total: 0
    };
    objTotal = _.reduce(
      objStoreUid,
      (acc, d, key) => {
        acc.pending += d.pending;
        acc.paid += d.paid;
        acc.total += d.total;
        return acc;
      },
      objTotal
    );

    setDataTable(objStoreUid);
    setObjTotal(objTotal);
    
    ;
  }
  function createObjShopList() {
    let newObjShops = {};
    _.forEach(shopList, s => {
      if (s.branchId) {
        newObjShops[s.uid] = {
          name: s.name,
          uid: s.uid
        };
      }
    });

    setObjShopList(newObjShops);
  }
  
  function clickCard(data) {
    ;
    window.$("#ModalLiveBranch").modal("show");
    setDataModal(data);
    
    ;
  }

  if (loading) {
    return (
      <div style={{ paddingTop: "30%" }}>
        <center>Loading...</center>
      </div>
    );
  }

  return (
    <div className="LivePageAll">
      {(!dataJobs || dataJobs.length < 1) && 
      
      <div class="row">
        <div class="col-12 mt-5">
        <AlertNodata />
        </div>
        
      </div>
      }
       {dataJobs.length >= 1 &&
          <div class="row">
          <div class="col-4">
            
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="exampleRadios2"
                value="table"
                onClick={e=>{setModeData(e.target.value)}}
                checked={modeData === 'table' ? true :false}

                
              />
              <label class="form-check-label" for="exampleRadios2" style={{marginTop:2}}>
                Table
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="exampleRadios1"
                value="card"
                onClick={e=>{setModeData(e.target.value)}}
                checked={modeData === 'card' ? true :false}
              />
              <label class="form-check-label" for="exampleRadios1" style={{marginTop:2}}>
                Card
              </label>
            </div>
          </div>
          <div class="col-8 text-right">
            <div class="Total">
            <img src="http://cdn3.livescore.com/web2/img/flash.gif" alt="live" style={{marginRight:3}}/>
            <span>Pending <strong  style={{marginLeft:20}}>{formatNum(objTotal.pending)}</strong> THB</span>
            </div>
            <div class="CountBills">
            <span style={{ float: "right"}}>
          Paid <span style={{marginLeft:20}}>{formatNum(objTotal.paid)} </span>THB
        </span>
            </div>
          </div>
        </div>
        }
      
     {modeData === 'card' &&
        <div class="row">
        
        {_.map(dataJobs, j => {
          return (
            <div class="col-12 mt-3">
              <CardLivebranch items={j} clickCard={clickCard} />
            </div>
          );
        })}
      </div>
     }
     {
       modeData === 'table' && dataJobs && dataJobs.length >= 1 &&
       <div class="row">
         <div class="col-12 mt-3">
           <TableReceipst receipts={dataJobs} noReceiptId={true} click={clickCard} live={true}/>
         </div>
       </div>
     }
     
      <ModalLiveBranch {...dataModal} />
    </div>
  );
};
export default LivePageBranch;
