import * as dynamo from "./dynamoDb";

export const fetchDataJobs = async (shopId, options = {}) => {
  let params = {
    TableName: "Spa_Jobs",
    KeyConditionExpression: "storeUid = :storeUid",
    ExpressionAttributeValues: {
      ":storeUid": shopId
      // ":jobUid": moment().format('YYYY-MM-DD')
      // ":jobUid": date
    },
    ExpressionAttributeNames: {
      "#jobUid": "jobUid"
    }
  };
  if (options.hqUid) {
    params = {
      TableName: "Spa_Jobs",
      IndexName: "hqUid-jobUid-index",
      KeyConditionExpression: "hqUid = :hqUid",
      ExpressionAttributeValues: {
        ":hqUid": options.hqUid
      }
    };
  }
  if (options.timestamp) {
    // fetch รายเดือน
    params["KeyConditionExpression"] += " and begins_with(#jobUid, :jobUid)";
    params["ExpressionAttributeValues"][":jobUid"] = options.timestamp;
    params["ExpressionAttributeNames"] = { "#jobUid": "jobUid" };
  }

  try {
    let res = await dynamo.dynamoDbClient.query(params).promise();

    return res.Items;

    return res.Items;
  } catch (error) {
    alert("fetchDataJobs :" + error);
  }
};
export const fetchDataCompute = async (shopId, options = {}) => {
  if (!shopId) return;
  var params = {
    TableName: "Spa_Compute_Data",
    KeyConditionExpression: "#storeUid = :storeUid ",
    ExpressionAttributeNames: {
      "#storeUid": "storeUid"
    },
    ExpressionAttributeValues: {
      ":storeUid": shopId
    }
  };

  try {
    let res = await dynamo.dynamoDbClient.query(params).promise();
    return res;
  } catch (error) {
    alert("fetchDataCompute" + error);
  }
};
export const fetchDataSummaryChart = async (shopId, options = {}) => {
  if (!shopId) return;
  var params = {
    TableName: "Spa_LiveScore",
    KeyConditionExpression: "#storeUid = :storeUid ",
    ExpressionAttributeNames: {
      "#storeUid": "storeUid"
    },
    ExpressionAttributeValues: {
      ":storeUid": shopId
    }
  };

  try {
    let res = await dynamo.dynamoDbClient.query(params).promise();
    return res;
  } catch (error) {
    alert("fetchDataCompute" + error);
  }
};