import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "../Component/CardReceipt";
import _ from "lodash";
import ModalReceipt from "../Component/ModalReceipt";
import moment from "moment";
import DatePicker from "../Component/DatePicker";
import formatNum from "format-num";
import { fetchDataReceipts, fetchDataReceiptsOld } from "../data/actions";
import AlertNoData from '../Component/AlertNodata'
import TableReceipts from '../Component/TableReceipts'
import {setLoading} from '../data/actions'

const ReceiptsPage = props => {
  const dispatch = useDispatch();

  // const [receipts, setReceipts] = useState([]);
  const [dataModal, setDataModal] = useState({});
  const [modeData, setModeData] = useState('table');


  let {
    appState,
    receipts_fetched,
  } = useSelector(state => {
    return {
      shopInfo: state.shopInfo,
      appState: state.appState,
      mounthList: state.mounthList,
      receipts_fetched: state.receipts_fetched
    };
  });

  // const {storeUid} =  shopInfo
  const { queryDate, queryMonth, queryYear, branchSelected } = appState;
  useEffect(() => {
    fetchData();
  }, [queryDate]);
  async function fetchData() {
    dispatch(setLoading(true))
    let options = {
      timestamp: queryDate
    };
    dispatch(fetchDataReceipts(branchSelected, options));
    dispatch(setLoading(false))

  }

  function clickCard(data) {
    window.$("#myModal2").modal("show");
    setDataModal(data);
  }

  function changeDate(dateEpocMillisec) {
    dispatch({
      type: "SET_APPSTATE",
      key: "queryDate",
      payload: moment(dateEpocMillisec).format("YYYY-MM-DD")
    });
  }

  return (
    <div className="ReceiptsPage">
      <div className="App">
     
        <div class="row ">
          <div class="col-12">
            <DatePicker fetchData={fetchData} changeDate={changeDate} />
          </div>
        </div>
        {receipts_fetched.length < 1&&
          <div class="row">
          <AlertNoData/>
        </div>
       }
        {receipts_fetched.length >= 1 &&
          <div class="row">
          <div class="col-4">
            
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="exampleRadios2"
                value="table"
                onClick={e=>{setModeData(e.target.value)}}
                checked={modeData === 'table' ? true :false}

                
              />
              <label class="form-check-label" for="exampleRadios2" style={{marginTop:2}}>
                Table
              </label>
            </div>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="exampleRadios"
                id="exampleRadios1"
                value="card"
                onClick={e=>{setModeData(e.target.value)}}
                checked={modeData === 'card' ? true :false}
              />
              <label class="form-check-label" for="exampleRadios1" style={{marginTop:2}}>
                Card
              </label>
            </div>
          </div>
          <div class="col-8 text-right">
            <div class="Total">
              <span>
                ยอดรวม{" "}
                <strong>{formatNum(_.sumBy(receipts_fetched, "payment.grandTotal"))}</strong> บาท
              </span>
            </div>
            <div class="CountBills">
              <span>จำนวนบิล {receipts_fetched.length} บิล</span>
            </div>
          </div>
        </div>
        }
        {/* <p class="mt-3 mb-3">
          <span>จำนวนบิล {receipts_fetched.length} บิล</span>
          <span style={{ float: "right" }}>
            ยอดรวม {formatNum(_.sumBy(receipts_fetched, "payment.grandTotal"))}{" "}
            บาท
          </span>
        </p> */}
       
       {modeData === 'card' &&
          <div class="row">
          {_.map(receipts_fetched, (r, key) => {
            return (
              <div class="col-12 mt-2">
                <Card
                  items={r}
                  clickCard={clickCard}
                  setDataModal={setDataModal}
                />
              </div>
            );
          })}
        </div>
       }
       {
         modeData === 'table' &&
         <div class="row">
           <div class='col-12 mt-2'>
              <TableReceipts receipts={receipts_fetched} click={clickCard}  />
           </div>
         </div>
       }

        <ModalReceipt dataModal={dataModal} />
      </div>
    </div>
  );
};
export default ReceiptsPage;
