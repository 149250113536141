import React,{useState} from "react";
import "../App.css";
import {cognitoSignOut} from '../data/cognito'
import { withRouter } from "react-router-dom";


const TabAll = props => {

    const {history} = props
  const [tabname,setTabname] = useState('Live')
    
    function clickTab(patch,tabname){
        history.push(patch)
        setTabname(tabname)
    }

    return (
      <ul
        class="nav mb-3 nav-fill"
        style={{
          flexWrap: "nowrap",
          overflowX: "auto",
          height: 48,
          borderBottom: "1px solid #e3e3e3"
        }}
      >
        <li class="nav-item">
          <a
          class={`nav-link ${tabname === 'Live'?'active':''}`}            
            href="#"
            onClick={e => {
              clickTab("/LivePageAll",'Live');
            }}
          >
            Live
          </a>
        </li>
  
        <li class="nav-item">
          <a
            class={`nav-link ${tabname === 'Summary'?'active':''}`}            
            href="#"
            onClick={e => {
              clickTab("/SummaryPageAll",'Summary');
            }}
          >
            Summary
          </a>
        </li>
  
        
  
        
  
      </ul>
    );
  
  return (
    // <nav class="nav flex-row">
    //             <a class="nav-link active text-white" onClick={e=>{clickTab('/')}}>Summary</a>
    //           <a class="nav-link active text-white" onClick={e=>{clickTab('/LivePageAll')}}>Live</a>
    //           <a class="nav-link active text-white" onClick={e=>{clickTab('/SatRate')}}>SatRate</a>
    // </nav>
    ///====
    <div>


      <a
        onClick={e => {
          clickTab("/");
        }}
      >
        Summary
      </a>

      <a
        onClick={e => {
          clickTab("/LivePageAll");
        }}
      >
        Live
      </a>
      
    
      <a
        onClick={e => {
          cognitoSignOut();
        }}
      >
        Logout
      </a>
    </div>
      ///=====

  );
};


export default withRouter(TabAll);


