import React, { useState, useEffect } from "react";

import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReceiptsPage from "./Pages/ReceiptsPage";
import UserRateReport from "./Pages/PageUserRateReport";
import SummaryPageAll from "./Pages/SummaryPageAll";
import LivePageAll from "./Pages/LivePageAll";
import SummaryPageBranch from "./Pages/SummaryPageBranch";
import { useSelector, useDispatch } from "react-redux";
import LivePageBranch from "./Pages/LivePageBranch";
import TherapistSummaryPageBranch from "./Pages/TherapistSummaryPageBranch";
import TherapistDetails from "./Pages/TherapistDetailsPage";
import AgentSummaryPage from "./Pages/AgentSummaryPage";
import AgentDetailsPage from "./Pages/AgentDetailsPage";
import EntertainSummaryPage from "./Pages/EntertainSummaryPage";
import EntertainDetailsPage from "./Pages/EntertainDetailsPage";
import ProductReportPage from "./Pages/ProductReportPage";
import TabModelBranch from "./Component/TabModeBranch";
import TabModeAll from "./Component/TabModeAll";
import SelectBranches from "./Component/SelectNavbar";
import NavEnhanced from "./Component/NavEnhanced";
import VoidsPage from "./Pages/VoidsPage";
import Channels from "./Pages/ChannelsSummaryPage";
import ChannelsDetails from "./Pages/ChannelsDetailsPage";
import Nations from "./Pages/NationsSummaryPage";
import NationsDetails from "./Pages/NationsDetailsPage";
import Loader from "react-loader-spinner";

const App = props => {
  let patchName = window.location.pathname;

  const dispatch = useDispatch();
  let { user, appState,loading } = useSelector(state => {
    return {
      loading: state.appState.loading,
      user: state.user,
      appState: state.appState
    };
  });
  const { mode } = appState;

  useEffect(() => {}, []);

  return (
    <div className="App">
      {loading && (
        <div className="loaderModal">
          <div className="loaderModalContent">
            <center>
              <Loader
                type="TailSpin"
                color="#00BFFF"
                height="300"
                width="300"
              />
            </center>
          </div>
        </div>
      )}

      <Router>
        <NavEnhanced />
        {mode === "branch" && <TabModelBranch />}
        {mode === "all" && <TabModeAll />}
        <div class="container  mb-5 pb-5 mt-3" style={{}}>
          <Route path="/select" exact component={SelectBranches} />
          <Route path="/" exact component={SelectBranches} />

          <Route path="/LivePageAll" exact component={LivePageAll} />
          <Route path="/SatRate" exact component={UserRateReport} />
          <Route
            path="/SummaryPageBranch"
            exact
            component={SummaryPageBranch}
          />
          <Route path="/SummaryPageAll" exact component={SummaryPageAll} />
          <Route path="/Receipts" exact component={ReceiptsPage} />
          <Route path="/Voids" exact component={VoidsPage} />
          <Route path="/LivePageBranch" exact component={LivePageBranch} />
          <Route
            path="/Therapist"
            exact
            component={TherapistSummaryPageBranch}
          />
          <Route path="/Therapist/Details" exact component={TherapistDetails} />
          <Route path="/AgentSummary" exact component={AgentSummaryPage} />
          <Route
            path="/AgentSummary/Details"
            exact
            component={AgentDetailsPage}
          />
          <Route
            path="/EntertainSummary"
            exact
            component={EntertainSummaryPage}
          />
          <Route
            path="/EntertainSummary/Details"
            exact
            component={EntertainDetailsPage}
          />
          <Route path="/ProductReport" exact component={ProductReportPage} />
          <Route path="/Channels" exact component={Channels} />
          <Route path="/Channels/Details" exact component={ChannelsDetails} />
          <Route path="/Nations" exact component={Nations} />
          <Route path="/Nations/Details" exact component={NationsDetails} />
        </div>
        <div class="footer"></div>
      </Router>
    </div>
  );
};
export default App;
