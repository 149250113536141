import { createStore, applyMiddleware } from "redux";
import reducer from "./reducer";
import initMiddleware from "../middleware/init.middleware";
import logger from "redux-logger";
import ReduxThunk from "redux-thunk";

let store = createStore(
  reducer,
  applyMiddleware(initMiddleware, logger, ReduxThunk)
);

store.dispatch({ type: "INIT" });

export default store;
