import * as dynamo from "./dynamoDb";
import _ from "lodash";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//TODO: ACtions to dispatch to state
export const setLoading = isLoad => {
  return { type: "SET_APPSTATE", key: "loading", payload: isLoad || false };
};

export const setEdit = isEdit => {
  return {
    type: "SET_APPSTATE",
    key: "editMode",
    payload: isEdit
  };
};

export const fetchLoggedInUser = (user, callback) => {
  return async (dispatch, getState) => {
    // let state = getState()

    const params = {
      TableName: "Minor_Users",
      KeyConditionExpression: "#username = :username",
      ExpressionAttributeNames: {
        "#username": "username"
      },
      ExpressionAttributeValues: {
        ":username": user
      },
      Limit: 1
    };

    try {
      // setTimeout(async () => {

      let res = await dynamo.dynamoDbClient.query(params).promise();
      console.log("===========query user======================");
      console.log();
      console.log("====================================");

      dispatch({
        type: "SET",
        key: "user",
        payload: res.Items[0]
      });
      dispatch(setLoading(false));
      callback();
    } catch (error) {
      alert(error);
      callback(error);
    }
  };
};

export const func_scan = (params, cb) => {
  dynamo.dynamoDbClient.scan(params, (err, data) => {
    if (err) {
      console.log(err);
      // alert(err)
    } else {
      cb(data);
    }
  });
};

export const init = cb => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET",
      key: "mounthList",
      payload: generateMonths()
    });
    dispatch({
      type: "SET",
      key: "yearList",
      payload: generateYears()
    });
    cb();
  };
};

export const fetchShopList = cb => {
  return (dispatch, getState) => {
    let { user } = getState();
    let param = {
      TableName: "Minor_Shops"
    };
    func_scan(param, data => {
      let inventory = []; //TODO: shopUidList

      _.forEach(data.Items, shop => {
        if (shop.link === undefined) {
          return;
        }
        if (
          shop.link.inventory !== undefined &&
          shop.link.inventory === user.shopId
        ) {
          inventory.push(shop);
        }
      });
      // inventory.push({branchId:'all',name:'All'})
      inventory = _.orderBy(inventory, "branchId", "asc");
      // inventory.splice(0, 0, {uid:'all',name:'All'})
      inventory = _.filter(inventory,i=>i.hide !== true)
      
      dispatch({
        type: "SET",
        key: "shopList",
        payload: inventory
      });

      setTimeout(cb, 500);
    });
  };
};

export const generateMonths = () => {
  let lastYearMonth = moment().subtract(2, "month");
  let monthArrlet = [];
  _.times(3, i => {
    monthArrlet.push({
      label: lastYearMonth.format("MMMM YYYY"),
      value: `${lastYearMonth.format("YYYY-MM")}`
    });
    lastYearMonth.add(1, "month");
  });

  let sortMonthArr = _.orderBy(monthArrlet, "value", "desc");
  return sortMonthArr;
};
export const generateYears = () => {
  let lastYear = moment().subtract(0, "year");
  let yearArrlet = [];
  _.times(1, i => {
    yearArrlet.push({
      label: lastYear.format("YYYY"),
      value: `${lastYear.format("YYYY")}`
    });
    lastYear.add(1, "year");
  });

  let sortMonthArr = _.orderBy(yearArrlet, "value", "desc");
  return sortMonthArr;
};

function conditionParam(shopId, options) {
  if (!shopId) return;
  var params = {
    TableName: "Spa_Receipts",
    KeyConditionExpression: "storeUid = :hkey",
    ExpressionAttributeValues: {
      ":hkey": shopId
    }
  };

  //ทุกสาขา
  if (options.hqUid) {
    params = {
      TableName: "Spa_Receipts",
      IndexName: "hqUid-timestamp-index",
      KeyConditionExpression: "hqUid = :hqUid",
      ExpressionAttributeValues: {
        ":hqUid": options.hqUid
      }
    };
  }
  let startDate = "";
  let endDate = "";

  if (options.timestamp) {
    startDate = options.timestamp;
    endDate = options.timestamp;
  } else if (options.dateRange) {
    startDate = options.dateRange.startDate;
    endDate = options.dateRange.endDate;
  }
  if (startDate !== "") {
    params["KeyConditionExpression"] +=
      " AND #timestamp BETWEEN :startDate AND :endDate";
    params["ExpressionAttributeValues"][":startDate"] = startDate;
    params["ExpressionAttributeValues"][":endDate"] =
      endDate + "zzzzzzzzzzzzzz";
    params["ExpressionAttributeNames"] = { "#timestamp": "timestamp" };
  }
  if (options.LastEvaluatedKey) {
    params["ExclusiveStartKey"] = options.LastEvaluatedKey;
  }
  // params["Limit"] = 200;
  return params;
}
export const fetchDataReceipts = (shopId, options = {}) => {
  return async (dispatch, getState) => {
    try {
      let receipts = [];
      let _lastkey = null;
      do {
        let params = conditionParam(shopId, { ...options });

        let data = await dynamo.dynamoDbClient.query(params).promise();

        receipts = [...receipts, ...data.Items];
        if (data.LastEvaluatedKey) {
          _lastkey = data.LastEvaluatedKey;
          options.LastEvaluatedKey = _lastkey
          
        } else {
          _lastkey = null;
          ;
        }
      } while (_lastkey != null);

      let tmpReceipts = [];
      let tmpVoidReceipts = [];
      let tmpEntertain = [];
      _.forEach(receipts, item => {
        if (item.void) {
          tmpVoidReceipts.push(item);
        } else if (item.entertain) {
          tmpEntertain.push(item);
        } else {
          tmpReceipts.push(item);
        }
      });
      tmpReceipts = _.orderBy(tmpReceipts, "jobUid", "desc");
      tmpVoidReceipts = _.orderBy(tmpVoidReceipts, "jobUid", "desc");
      tmpEntertain = _.orderBy(tmpEntertain, "jobUid", "desc");
      dispatch({
        type: "SET",
        key: "receipts_fetched",
        payload: tmpReceipts
      });

      dispatch({
        type: "SET",
        key: "voids_fetch",
        payload: tmpVoidReceipts
      });
      dispatch({
        type: "SET",
        key: "receipts_entertain_fetch",
        payload: tmpEntertain
      });

      // if (options.LastEvaluatedKey) {
      //   let { receipts_fetched } = getState(state => {
      //     return { receipts_fetched };
      //   });
      //   let newre = receipts_fetched;
      //   newre.push(...tmpReceipts);
      //   dispatch({
      //     type: "SET",
      //     key: "receipts_fetched",
      //     payload: newre
      //   });
      //
      // }
      // else if (res.LastEvaluatedKey) {
      //   dispatch({
      //     type: "SET",
      //     key: "receipts_fetched",
      //     payload: tmpReceipts
      //   });

      //   dispatch({
      //     type: "SET",
      //     key: "voids_fetch",
      //     payload: tmpVoidReceipts
      //   });
      //   dispatch({
      //     type: "SET",
      //     key: "receipts_entertain_fetch",
      //     payload: tmpEntertain
      //   });
      //   let newOptions = options;
      //   newOptions["LastEvaluatedKey"] = res.LastEvaluatedKey;

      //   dispatch(fetchDataReceipts(shopId, options));
      //
      // }
      // else {
      //   dispatch({
      //     type: "SET",
      //     key: "receipts_fetched",
      //     payload: tmpReceipts
      //   });

      //   dispatch({
      //     type: "SET",
      //     key: "voids_fetch",
      //     payload: tmpVoidReceipts
      //   });
      //   dispatch({
      //     type: "SET",
      //     key: "receipts_entertain_fetch",
      //     payload: tmpEntertain
      //   });
      // }
    } catch (error) {
      alert(error);
    }
  };
};
export const fetchAddDataReceipts = (shopId, options = {}) => {
  return async (dispatch, getState) => {
    let params = conditionParam(shopId, options);

    try {
      let res = await dynamo.dynamoDbClient.query(params).promise();

      let tmpReceipts = [];
      let tmpVoidReceipts = [];
      let tmpEntertain = [];
      _.forEach(res.Items, item => {
        if (item.void) {
          tmpVoidReceipts.push(item);
        } else if (item.entertain) {
          tmpEntertain.push(item);
        } else {
          tmpReceipts.push(item);
        }
      });
      tmpReceipts = _.orderBy(tmpReceipts, "jobUid", "desc");
      tmpVoidReceipts = _.orderBy(tmpVoidReceipts, "jobUid", "desc");
      tmpEntertain = _.orderBy(tmpEntertain, "jobUid", "desc");

      let { receipts_fetched } = getState(state => {
        return { receipts_fetched };
      });

      let newReceipts = receipts_fetched;
      newReceipts.push(...tmpReceipts);

      dispatch({
        type: "SET",
        key: "receipts_fetched",
        payload: newReceipts
      });
      dispatch({
        type: "SET",
        key: "voids_fetch",
        payload: tmpVoidReceipts
      });
      dispatch({
        type: "SET",
        key: "receipts_entertain_fetch",
        payload: tmpEntertain
      });
      if (res.LastEvaluatedKey) {
        let newOptions = options;
        newOptions["LastEvaluatedKey"] = res.LastEvaluatedKey;
        dispatch(fetchAddDataReceipts(shopId, options));
      }
    } catch (error) {
      alert(error);
    }
  };
};

export const fetchDataCompute = async (shopId, options = {}) => {
  if (!shopId) return;
  var params = {
    TableName: "Spa_Compute_Data",
    Key: {
      storeUid: shopId,
      name: "products"
    }
  };

  try {
    let res = await dynamo.dynamoDbClient.get(params).promise();
    return res;
  } catch (error) {
    alert("fetchDataCompute" + error);
  }
};
export const fetchDataJobs = async (shopId, options = {}) => {
  let params = {
    TableName: "Spa_Jobs",
    KeyConditionExpression: "storeUid = :storeUid",
    ExpressionAttributeValues: {
      ":storeUid": shopId
      // ":jobUid": moment().format('YYYY-MM-DD')
      // ":jobUid": date
    },
    ExpressionAttributeNames: {
      "#jobUid": "jobUid"
    }
  };
  if (options.hqUid) {
    params = {
      TableName: "Spa_Jobs",
      IndexName: "hqUid-jobUid-index",
      KeyConditionExpression: "hqUid = :hqUid",
      ExpressionAttributeValues: {
        ":hqUid": options.hqUid
      }
    };
  }
  if (options.timestamp) {
    // fetch รายเดือน
    params["KeyConditionExpression"] += " and begins_with(#jobUid, :jobUid)";
    params["ExpressionAttributeValues"][":jobUid"] = options.timestamp;
    params["ExpressionAttributeNames"] = { "#jobUid": "jobUid" };
  }

  try {
    let res = await dynamo.dynamoDbClient.query(params).promise();

    return res.Items;

    return res.Items;
  } catch (error) {
    alert("fetchDataJobs :" + error);
  }
};
