import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { color } from "highcharts";

const list = [
  {
    name: "Yesterday",
    startDate: moment()
      .add(-1, "day")
      .startOf("day"),
    endDate: moment()
      .add(-1, "day")
      .endOf()
  },
  {
    name: "Today",
    startDate: moment().startOf("day"),
    endDate: moment().endOf("day")
  },
  {
    name: "This Month",
    startDate: moment().startOf("month"),
    endDate: moment().endOf("month")
  },
  {
    name: "Last Month",
    startDate: moment()
      .add(-1, "month")
      .startOf("month"),
    endDate: moment()
      .add(-1, "month")
      .endOf("month")
  },
  {
    name: "Custom",
    startDate: moment()
      .add(-1, "month")
      .startOf("month"),
    endDate: moment()
      .add(-1, "month")
      .endOf("month")
  }
];
const TableHeaderDetailPage = props => {
  const dispatch = useDispatch();
  let { appState } = useSelector(state => {
    return {
      appState: state.appState
    };
  });

  const { headDetailPage,mode_queryDate } = appState;
  const { Header } = props;
  const { objValue = {} } = props;

  function handleSetQueryRange(queryDate) {
    dispatch({
      type: "SET_APPSTATE",
      key: "startDate",
      payload: queryDate.startDate.format("YYYY-MM-DD")
    });

    dispatch({
      type: "SET_APPSTATE",
      key: "endDate",
      payload: queryDate.endDate.format("YYYY-MM-DD")
    });
    let name = queryDate.name.toLowerCase()
    dispatch({
      type: "SET_APPSTATE",
      key: "mode_queryDate",
      payload: name
    });
  }

  const padding = {
    padding: "8px 0px 8px 4px"
  };

  return (
    <div class="row">
      
  
      <div class='col-12'>
      <div
              class="btn-group btn-group-sm"
              role="group"
              aria-label="Basic example"
              style={{   
                fontSize : '0.8em',
                width:'100%'

              }}
            >
              {_.map(list, l => (
                <button
                  type="button"
                  style={{   
                    fontSize : '0.8em',
                    backgroundColor:'#E9ECEF',
                    color:'#495057',
                    borderColor:'rgb(206, 212, 218)'
                    
                  }}
                  class={`btn btn-secondary ${mode_queryDate.toLowerCase() === l.name.toLowerCase() ? 'active focus' : ''}`}
                  onClick={() => handleSetQueryRange(l)}
                >
                  {l.name}
                </button>
              ))}
            </div>
      </div>

      <div class="col-6 mt-2">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm">
              Start
            </span>
          </div>
          <input
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-sm"
            value={appState.startDate}
          />
        </div>
      </div>
      <div class="col-6 mt-2">
        <div class="input-group input-group-sm mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-sm">
              End
            </span>
          </div>
          <input
            type="text"
            class="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-sm"
            value={appState.endDate}
          />
        </div>{" "}
      </div>
    </div>
  );
  
};

export default TableHeaderDetailPage;
