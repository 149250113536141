import React, { useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import formatNum from "format-num";
import {listTherapistName,getTimeStamp} from '../data/funcGlobal'

const CardReceipt = props => {
  useEffect(() => {}, []);
  const { items = {} } = props;

  const { payment = {} } = items;

  const { clickCard, voids } = props;

  function mapToIsoString(timestamp) {
    return _.split(timestamp, "#")[1] || "";
  }
  // var rows = [];

  return (
    <div
      class="card shadow"
      onClick={e => {
        clickCard(items);
      }}
    >
      <div
        class="card-body"
        style={{ paddingLeft: "12px", paddingRight: "12px" }}
      >
        <div class="row no-gutters">
          <div class="col-8 pl-0">
            <p>
              <i class="fas fa-calendar-alt mr-1" style={{ color: "grey" }}></i>
              <small>
                <strong>
                  {moment(getTimeStamp(items.timestamp)).format("HH:mm") +
                    "  "}
                </strong>
                {moment(getTimeStamp(items.timestamp)).format("DD MMM YYYY")}
              </small>
            </p>
          </div>
          <div class="col-4 text-right pr-0">
            <p>
              <span style={{ color: "red" }} hidden={voids ? false : true}>
                (void)
              </span>
              <small>
                <strong>{formatNum(items.payment.grandTotal)}</strong>
              </small>
            </p>
          </div>
          <div class="col-6">
            <p>
              <i
                class="fas fa-user mr-1"
                style={{ color: "grey", fontSize: 14 }}
              ></i>
              <small>
                <strong>
                  {(items.member &&
                    items.member.name &&
                    items.member.name.toUpperCase()) ||
                    "-"}
                </strong>
              </small>
            </p>
          </div>
          {items.therapist && (
            <div class="col-6 text-right pr-0">
              <p>
                <small>
                  <em style={{ color: "grey" }}>
                    {items.therapist ? "by  " + listTherapistName(items.therapist) : ""}
                  </em>
                </small>
              </p>
            </div>
          )}
        </div>
        {items.items ? (
          <div class="row card-text">
            <div class="col-12">
              <p>
                {/* <i class="fas fa-archive mr-1" style={{ color:'grey',fontSize:14}}></i> */}
                <small>
                  {/* <em style={{color:'grey'}}> */}
                  {_.map(items.items, (i, key) => {
                    return [
                      <i
                        class="fas fa-archive mr-1"
                        style={{
                          color: "grey",
                          fontSize: 14,
                          visibility: `${key != 0 ? "hidden" : ""}`
                        }}
                        key={key}
                      ></i>,
                      <span>
                        <em style={{ color: "grey" }}>
                          <b>{i.qty} X </b> {i.name}
                        </em>
                      </span>,
                      <br />
                    ];
                  })}

                  {/* </em> */}
                </small>
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
        {items.remark ? (
          <div class="row card-text">
            <div class="col-12">
              <p>
                <i
                  class="fas fa-comment-dots"
                  style={{ color: "grey", fontSize: 14 }}
                ></i>
                <small>
                  <em style={{ color: "grey" }}>{items.remark}</em>
                </small>
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CardReceipt;





// sheet_id='1NnxsPSDkj0t6Xh-2Yev2P4CL8IesO2WnOlziatijFDU'
// const API = "AIzaSyACJ3nFnsEcxogpnEFAE63OAdi6yTBHSkk";

//------
