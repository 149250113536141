import React, { useState, useEffect } from "react";
import _ from "lodash";

import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import CardTableGlobal from "../Component/CardTableGlobal";
import TableHeader from "../Component/DateRange";
import AlertNodata from "../Component/AlertNodata";
import { fetchDataReceipts,setLoading } from "../data/actions";
import formatNum from 'format-num'

const EntertainSummaryPage = props => {
  let { appState, receipts_entertain_fetch } = useSelector(state => {
    return {
      appState: state.appState,
      receipts_entertain_fetch: state.receipts_entertain_fetch
    };
  });
  const dispatch = useDispatch();
  const {
    queryDate,
    queryMonth,
    queryYear,
    branchSelected,
    headDetailPage,
    startDate,
    endDate
  } = appState;
  const [queryMode, setqueryMode] = useState("date");
  const [listHeader, setListHeader] = useState(["Name", "Bills", "GrandTotal"]);
  const [listData, setListData] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);
  useEffect(() => {
    computedData(receipts_entertain_fetch);
  }, [receipts_entertain_fetch]);

  function clickRow(value) {
    dispatch({
      type: "SET_APPSTATE",
      key: "querySelected",
      payload: value
    });
    props.history.push("/EntertainSummary/Details");
  }

  async function fetchData() {
    dispatch(setLoading(true))
    let options = {
      dateRange: {
        startDate: appState.startDate,
        endDate: appState.endDate
      }
    };

    dispatch(await fetchDataReceipts(branchSelected,options))

  }

  function clickGo() {
    let newQueryDate =
      queryMode === "date"
        ? queryDate
        : queryMode === "month"
        ? queryMonth
        : queryYear;

    fetchData(newQueryDate);
  }
  function computedData(receipts) {
    let obj_entertain = {};
    obj_entertain = _.reduce(
      receipts,
      (acc, d, key) => {
        if (d.entertain) {
          if (acc[d.entertain.uid] == undefined) {
            acc[d.entertain.uid] = {
              name: d.entertain.name,
              uid: d.entertain.uid,
              bills: 0,
              grandTotal: 0
            };
          }

          acc[d.entertain.uid].grandTotal += d.payment.grandTotal;
          
          acc[d.entertain.uid].bills += 1;
        }
        return acc;
      },
      {}
    );
    
    let arr_entertain = [];
    _.forEach(obj_entertain, o => {
      // if (o.name && o.bills && o.grandTotal) {
        arr_entertain.push({
          uid: o.uid || '',
          att1: o.name || '-',
          att2: o.bills || 0,
          att3: o.grandTotal || 0
        });
      // }
    });
    
    let arr_entertain_orderby = _.orderBy(arr_entertain, "att3", "desc");
    
    setListData(arr_entertain_orderby);
    dispatch(setLoading(false))

  }
  return (
    <div className="App">
      
      <div class="row">
        <div class="col-12">
          <TableHeader />
        </div>
        <div class="col-12 mt-2">
          {listData.length < 1 && <AlertNodata />}
          { listData.length >= 1 && 
         (
          <p class='mt-2 mb-3'>
          <span>จำนวนบิล {listData.length} บิล</span>
          <span style={{ float: "right" }}>
            ยอดรวม <strong>{formatNum(_.sumBy(listData, "att3"))}</strong> บาท
          </span>
        </p>
         )
      }
          {listData.length >= 1 && (
            <CardTableGlobal
              HeadCard={"Therapist"}
              listHeader={listHeader}
              listData={listData}
              clickRow={clickRow}
            />
          )}
        </div>
      </div>

      <div class="row mt-4"></div>
    </div>
  );
};

export default EntertainSummaryPage;
