import React, { useState, useEffect } from "react";

import _ from "lodash";
import formatNum from 'format-num'


const CollapsItemsInReceive = props => {
  
  const { Items, dataHolder } = props;
  ;

  const { name, id } = props;
  const [toggle, setToggle] = useState(false);

  const [thisGroupHasSales, setThisGroupHasSales] = useState(false);

  //   useEffect(() => {
  //      let found =  _.find(Items, (i)=>{
  //          return  objItems[i.name] && objItems[i.name].grandTotal
  //       })
  //       setThisGroupHasSales(found)
  //   }, [Items, objItems ])

  //   if(!thisGroupHasSales){
  //     return null
  //   }

  return (
    <div>
      <h6 
      onClick={() => setToggle(!toggle)}>
        {" "}
        {toggle ? "+" : "-"} {name}
        <small><em>  Click to expand</em></small>
      </h6>
      
    {toggle && <hr />}
    {!toggle && <ListItems items={Items} dataHolder={dataHolder} name={name}  />}
    </div>
  );
};
export default CollapsItemsInReceive;

function ListItems({ items = [],dataHolder={} ,name =''}) {
  
 
  
  return (
    <div class="list-group list-group-flush mb-5">
      {_.map(items, i => {
          
       
        return (
          <li class="list-group-item d-flex justify-content-between align-items-center p-0 pt-1">
            <div>
              <h6>
                <span style={{ color: "blue" }}>
                  {" "}
                  <b>{i.qty && i.qty} </b>{" "}
                </span>
                <small> {i.name}</small>
              </h6>
            </div>
            <div>
              <span class="number">
                {i.extendedPrice && formatNum(i.extendedPrice)}
              </span>
            </div>
          </li>
        );
      })}
    </div>
  );
}
