import React from "react";
import "../App.css";
import { cognitoSignOut } from "../data/cognito";

const ToggleDateMode = props => {
  const { changequeryMode,queryMode } = props;
  
  return (
    <select
    class="form-control"
    id="exampleFormControlSelect1"
    value={queryMode}
    onChange={e => {
      changequeryMode(e.target.value);
    }}
  >
    
          <option value={'date'}>Day</option>
          <option value={'month'}>Month</option>
          <option value={'year'}>Year</option>
      
    }
  </select>
  );
  return (
    <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label class="btn  btn-secondary active" onClick={e=>{changequeryMode('date')}}>
                <input type="radio" name="options" id="option1" autocomplete="off" checked /> Day
            </label>
            <label class="btn  btn-secondary" onClick={e=>{changequeryMode('month')}}>
                <input type="radio" name="options" id="option2" autocomplete="off" /> Month
            </label>
            <label class="btn  btn-secondary" onClick={e=>{changequeryMode('year')}}>
                <input type="radio" name="options" id="option3" autocomplete="off" /> Year
            </label>
            </div>
  );
};
export default ToggleDateMode;
