import React from 'react'
import _ from 'lodash'
import formatNum from 'format-num'


const CardTableGlobal = (props) => { 
    const {listHeader,listData , Header,clickRow} = props
    
        return (  
        <div className="CardTable">
            <div className="card shadow">
                <div className="card-header" hidden={!Header}>
                    {Header}
                </div>
                <div className="card-body p-0">
                <table class="table mb-0 table-striped ">
                    <thead>
                        <tr>
                       {_.map(listHeader,((h,key)=>{
                        
                        
                        if (h==='GrandTotal') {
                            return(

                                <th scope="col" class="text-right" style={{borderBottom:'none',borderTop:'none'}}>{h}</th>
                                
    
                            )
                        }
                        return(
                            <th scope="col" style={{borderBottom:'none',borderTop:'none'}}>{h}</th>
                            )
                       }))}
                       
                        </tr>
                    </thead>
                    <tbody>
                                {
                        _.map(listData,((d,key)=>{
                            
                            return(
                            <tr onClick={e=>{clickRow(d)}}>
                                {d.att1 && <td style={{borderBottom:'none',borderTop:'none'}}><small class="text-primary">{d.att1}</small></td>}
                                {d.att2 && <td style={{borderBottom:'none',borderTop:'none'}}>{d.att2}</td>}
                                {d.time && <td style={{borderBottom:'none',borderTop:'none'}}><small><em style={{color:'greyy'}}>{d.time}</em></small></td>}
                                {d.att3 >= 1 && <td class="text-right" style={{borderBottom:'none',borderTop:'none'}}>{d.att3 ? formatNum(d.att3) : 'eem'}</td>}
                                {d.att3 < 1 && <td class="text-right" style={{borderBottom:'none',borderTop:'none',color:'red'}}>{'0'}</td>}
                            </tr>
                            )
                        }))
                    }
                    </tbody>
                </table>
                
                </div>
            </div>
            <div class="list-group" hidden>
                {
                        _.map(listData,((d,key)=>{
                            
                            return(
                                <a href="#" class="list-group-item list-group-item-action" onClick={e=>{clickRow(d)}}>
                                    <div class="row">
                                    {d.att1 && <span class="col-4">{d.att1}</span>}
                                    {d.att2 && <span class="col-4">{d.att2}</span>}
                                    {d.att2 && <span class="col-4">{d.att3}</span>}
                                    </div>
                                </a>
                            )
                        }))
                    }
                </div>
        </div>
    )
    
}
export default CardTableGlobal;