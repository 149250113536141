import React from 'react'
import _ from 'lodash'
import formatNum from 'format-num'


const CardTable = (props) => { 
    const {paymentType} = props
        return (  
        <div className="CardTable">
            <div className="card">
                <div className="card-header">
                    PaymentType
                </div>
                <div className="card-body p-0">
                <table class="table  mb-0  table-striped  ">
                     
                    <tbody>
                                {
                        _.map(paymentType,((t,key)=>{
                            
                            return(
                            <tr >
                                <td>{key}</td>
                                <td class='text-right'>{formatNum(t)} THB</td>
                            </tr>
                            )
                        }))
                    }
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    )
    
}
export default CardTable;