import React, { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import Icon from "./Icon";
import ModalLiveBranch from "./ModalLiveBranch";
import formatNum from "format-num";

const CardLiveBranch = props => {
  const { items, clickCard } = props;

  const [objAnswers, setObjAnswers] = useState({});
  const [objDef, setObjDef] = useState({});

  useEffect(() => {
    // mapData()
  }, []);

  return (
    <div
      class="card shadow"
      onClick={e => {
        clickCard(items);
      }}
    >
      <div class="card-body">
        <div class="row no-gutters">
          <div class="col-8">
            <p>
              <Icon name={"fas fa-door-closed"} />
              <small>
                <strong>
                  {items.meta.room.name} - {items.meta.bed.name}
                </strong>
              </small>
            </p>
          </div>
          <div class="col-4 text-right pr-1">
            <p>
              <small>
                <strong>{formatNum(items.grandTotal)}</strong>
              </small>
            </p>
          </div>

          <div class="col-6">
            <p>
              <Icon name={"fas fa-user"} />
              <small>
                <strong>
                  {(items.member &&
                    items.member.name &&
                    items.member.name.toUpperCase()) ||
                    "-"}
                </strong>
              </small>
            </p>
          </div>

          <div class="col-6 text-right pr-1">
            <p>
              <small>
                {items.therapist && items.therapist.name !== "" ? (
                  <em style={{ color: "grey" }}>
                    {"by " + items.therapist.name}
                  </em>
                ) : (
                  ""
                )}
              </small>
            </p>
          </div>
        </div>
        {items.items ? (
          <div class="row card-text">
            <div class="col-12">
              <p>
                {/* <i
                  class="fas fa-archive mr-1"
                  style={{ color: "grey", fontSize: 14 }}
                ></i> */}
                <small>
                  {/* <em style={{color:'grey'}}> */}
                  {_.map(items.items,(i,key) => {
                    return [
                      <i
                        class="fas fa-archive mr-1"
                        style={{
                          color: "grey",
                          fontSize: 14,
                          visibility: `${key != 0 ? "hidden" : ""}`
                        }}
                        key={key}
                      ></i>,
                      <span>
                        <em style={{ color: "grey" }}>
                          <b>
                            {items.qtyHolder[i.uid] && items.qtyHolder[i.uid]} X{" "}
                          </b>{" "}
                          {i.name}
                        </em>
                      </span>,
                      <br />
                    ];
                  })}

                  {/* </em> */}
                </small>
              </p>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CardLiveBranch;

//------
