import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import CardReceipt from "../Component/CardReceipt";
import ModalReceipt from "../Component/ModalReceipt";
import ButtonBack from "../Component/ButtonBack";

const NationsDetailsPage = props => {
  let { appState, receipts_fetched } = useSelector(state => {
    return {
      appState: state.appState,
      receipts_fetched: state.receipts_fetched
    };
  });

  const dispatch = useDispatch();
  const { querySelected } = appState;
  const [dataModal, setDataModal] = useState({});
  const [dataCard, setDataCard] = useState([]);

  const [Header, setHeader] = useState({
    att1: "Name",
    att2: "Bills",
    att3: "GranTotal"
  });

  useEffect(() => {
    filter(receipts_fetched);
  }, [receipts_fetched]);

  function filter(receipts) {
    let receipts_filter = [];
    _.forEach(receipts, r => {
      if (r.member) {
        if (r.member.country === querySelected.att1) {
          receipts_filter.push(r);
        } else {
          return null;
        }
      }
    });
    let receipts_filter_orderby = _.orderBy(
      receipts_filter,
      "timestamp",
      "desc"
    );
    setDataCard(receipts_filter_orderby);
  }
  function clickCard(data) {
    window.$("#myModal2").modal("show");
    setDataModal(data);
  }

  return (
    <div className="App">
      <div class="row">
        <div class="col-12">
          <ButtonBack history={props.history} />
        </div>
      </div>

      <div class="row">
        {_.map(dataCard, r => {
          return (
            <div class="col-12 mt-2">
              <CardReceipt items={r} clickCard={clickCard} />
            </div>
          );
        })}
      </div>
      <ModalReceipt dataModal={dataModal} />
    </div>
  );
};

export default NationsDetailsPage;
