import React, { useEffect, useState } from "react";
import {  useDispatch } from "react-redux";
import {LogIn} from '../data/cognito'
// import {setLoading} from '../data/actions'













const ReceieptPage = props => {


    const [username,setUsername] = useState('')
    const [password,setPassword] = useState('')

  const dispatch = useDispatch();

    function clickLogin (){
    //   setLoading(true)
      LogIn(username,password)
      
    }
    return(
        <div className="Login">
            <div class="container-fluid" id="Login">
            <div class="card">
          <div class="card-header card-header-divider text-center">
                <h4>Report Mobile</h4>
                <small class="splash-description">Please enter your username and password.</small>
            </div>
            
          <div class="card-body">


            <div class="form-group ">
                    <label>Username</label>
                <input
                  class="form-control"
                  placeholder="Username"
                  type="text"
                  value={username}
                  onChange={(e)=>{setUsername(e.target.value)}}
                  
                  
                />
            </div>
            <div class="form-group ">
                    <label>Password</label>
                <input
                  class="form-control"
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={(e)=>{setPassword(e.target.value)}}
                />
            </div>
            <div class="form-group login-submit">
            <a class="btn btn-primary btn-xl text-white" id="btnLogin" onClick={e=>{clickLogin()}}>Sign me in</a>
            </div>
           
          

            
          </div>
        </div>
            </div>
        </div>
    )
}


export default ReceieptPage