import async from "async";
import { getUser } from "../data/cognito";
import {
  fetchLoggedInUser,
  fetchShopList,
  setLoading,
  init
} from "../data/actions";

const InitMiddleware = store => next => {
  return async action => {
    if (action.type === "INIT") {
      //fetch
      let user = getUser();
      if (!user) return;
      async
        .waterfall([
          cb => {
            store.dispatch(fetchLoggedInUser(user.username, cb));
          },
           cb => {
            // let promise = new Promise(async resolve => {
            //   let count = 0;
            //   function incrementCount() {
            //     count++;
            //     if (count === 2) {
            //       resolve();
            //     }
            //   }

             

              
            // });
            // await promise;
            // cb();
            store.dispatch(fetchShopList(cb));
          },
          cb=>{
            store.dispatch(init(cb));
          }
        ])
        .catch(err => {
          console.log(err);
        });

      //TODO: Refactor to async await
      // let res1 = await store.dispatch(fetchLoggedInUser(user.username));

      // let res2 = await store.dispatch(fetchShopList());
      // let res3 = await store.dispatch(init())
      
    }
    next(action);
  };
};

// const InitMiddleware = store => next => {
//   return async action => {
//     if (action.type === "INIT") {
//       //fetch
//       let user = getUser();
//       if (!user) return;
//       async
//         .waterfall([
//           cb => {
//             store.dispatch(fetchLoggedInUser(user.username, cb));
//           },
//           async cb => {
//             let promise = new Promise(async resolve => {
//               let count = 0;
//               function incrementCount() {
//                 count++;
//                 if (count === 2) {
//                   resolve();
//                 }
//               }

//               store.dispatch(fetchShopList(incrementCount));
//               store.dispatch(init(incrementCount));

              
//             });
//             await promise;
//             cb();
//           }
//         ])
//         .catch(err => {
//           console.log(err);
//         });

//       //TODO: Refactor to async await
//       // let res1 = await store.dispatch(fetchLoggedInUser(user.username));

//       // let res2 = await store.dispatch(fetchShopList());
//       // let res3 = await store.dispatch(init())
//     }
//     next(action);
//   };
// };


// const InitMiddleware = store => next => {
//   return async action => {
//     if (action.type === "INIT") {
//       //fetch
//       let user = getUser();
//       if (!user) return;

//       let promise = new Promise(async resolve => {
//         let count = 0;
//         function incrementCount() {
//           count++;
//         }

//         store.dispatch(fetchLoggedInUser(user.username, incrementCount));

//         setTimeout(() => {
//           store.dispatch(fetchShopList(incrementCount));
//           store.dispatch(init(incrementCount));
//         }, 2000);

//         if (count === 3) {
//           resolve();
//         }
//       });

//       next(action);

//       //TODO: Refactor to async await
//       // async.waterfall([
//       //     cb => {
//       // store.dispatch(fetchLoggedInUser(user.username, cb));

//       //     },
//       //     cb => {

//       //     },

//       //   ]);
//     } else {
//       next(action);
//     }
//   };
// };

export default InitMiddleware;
