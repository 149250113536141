import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import CardTableGlobal from "../Component/CardTableGlobal";
import TableHeader from "../Component/DateRange";
import { fetchDataReceipts,setLoading } from "../data/actions";
import AlertNodata from "../Component/AlertNodata";
import formatNum from "format-num";

const AgentSummaryPage = props => {
  let { appState, receipts_fetched } = useSelector(state => {
    return {
      appState: state.appState,
      receipts_fetched: state.receipts_fetched
    };
  });
  const dispatch = useDispatch();
  const {
    queryDate,
    queryMonth,
    queryYear,
    branchSelected,
    startDate,
    endDate
  } = appState;
  const [queryMode, setqueryMode] = useState("date");
  const [listHeader, setListHeader] = useState(["Name", "Bills", "GrandTotal"]);
  const [listData, setListData] = useState([]);
  const [data, setData] = useState([]);
  const [totalBills, setTotalBills] = useState(0);


  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);
  useEffect(() => {
    computedData(receipts_fetched);
  }, [receipts_fetched]);

  function clickRow(value) {
    dispatch({
      type: "SET_APPSTATE",
      key: "querySelected",
      payload: value
    });
    props.history.push("/AgentSummary/Details");
  }

  async function fetchData() {
    dispatch(setLoading(true))
    let options = {
      dateRange: {
        startDate: appState.startDate,
        endDate: appState.endDate
      }
    };

    dispatch(await fetchDataReceipts(branchSelected, options));
  }

  function computedData(receipts) {
    let obj_agent_receipts = {};
    let newTotalBills = 0
    obj_agent_receipts = _.reduce(
      receipts,
      (acc, r, key) => {
        if (r.agentDetail && r.agentDetail.payload) {
          let agent  = r.agentDetail.payload
          if (acc[agent.uid] === undefined) {
            acc[agent.uid] = {
              ...agent,
              bills: 0,
              grandTotal: 0
            };
          }
          if (acc[agent.uid]) {
            acc[agent.uid].grandTotal += r.payment.grandTotal;
            acc[agent.uid].bills += 1;
            newTotalBills += 1
          }
        }
        return acc;
      },
      {}
    );
    
    let agent_receipts = [];
    _.forEach(obj_agent_receipts, o => {
      // if (o.name && o.bills && o.grandTotal) {
        agent_receipts.push({
          uid: o.uid,
          att1: o.name,
          att2: o.bills,
          att3: o.grandTotal
        });
      // }
    });

    let agent_receipts_orderby = _.orderBy(agent_receipts, "att3", "desc");
    setListData(agent_receipts_orderby);
    setTotalBills(newTotalBills)
    dispatch(setLoading(false))
  }
  return (
    <div className="App">
      <div class="row">
        <div class="col-12">
          <TableHeader />
        </div>
        {listData.length < 1 && (
          <div class="col-12 mt-2">
            <AlertNodata />
          </div>
        )}

        {listData.length >= 1 && (
          <div class="col-12 mt-2">
            <p class="mt-2 mb-3">
              <span>จำนวนบิล {totalBills} บิล</span>
              <span style={{ float: "right" }}>
                ยอดรวม <strong>{formatNum(_.sumBy(listData, "att3"))}</strong> บาท
              </span>
            </p>
            <CardTableGlobal
              HeadCard={"Therapist"}
              listHeader={listHeader}
              listData={listData}
              clickRow={clickRow}
            />
          </div>
        )}
      </div>

      <div class="row mt-4"></div>
    </div>
  );
};

export default AgentSummaryPage;
