import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import CardGraph from "../Component/CardGraph";
import _ from "lodash";
import ToggleDateMode from "../Component/ToggleDateMode";
import DatePicker from "../Component/DatePicker";
import { fetchDataReceipts } from "../data/actions";

// import moment from "moment";
// import _ from "lodash";
// import * as Dynamo from "./plugin/dynamoDb";

const SummaryPageAll = () => {
  const [queryMode, setqueryMode] = useState("date");
  const [dataGraph, setDataGraph] = useState({});
  const [objShopList, setObjShopList] = useState(null);
  const [optionsGraph, setOptionsGraph] = useState({});

  let {
    appState,
    mounthList,
    user,
    shopList,
    yearList,
    receipts_fetched
  } = useSelector(state => {
    return {
      appState: state.appState,
      mounthList: state.mounthList,
      yearList: state.yearList,
      user: state.user,
      shopList: state.shopList,
      receipts_fetched: state.receipts_fetched
    };
  });
  const dispatch = useDispatch();
  const { queryDate, queryMonth, queryYear } = appState;

  useEffect(() => {
    if (shopList) {
      createObjShopList();
    }
  }, [shopList]);

  useEffect(() => {
    if (user && objShopList) {
      let newDate = queryMode === 'date' ? queryDate : queryMode === 'month' ? queryMonth : queryYear
      fetchData(newDate);
    }
  }, [user, objShopList, queryDate, queryMonth, queryYear]);
  useEffect(() => {
    
    computedData(receipts_fetched);
  }, [receipts_fetched,]);

  function changequeryMode(value) {
    let newDate =
      value === "date" ? queryDate : value === "month" ? queryMonth : queryYear;

    setqueryMode(value);
    fetchData(newDate);
  }

  function changeDay(value) {
    dispatch({
      type: "SET_APPSTATE",
      key: "queryDate",
      payload: moment(value).format("YYYY-MM-DD")
    });
  }
  function changeMounth(value) {
    dispatch({
      type: "SET_APPSTATE",
      key: "queryMonth",
      payload: moment(value).format("YYYY-MM")
    });
    // fetchData(queryMonth);
  }
  function changeYear(value) {
    dispatch({
      type: "SET_APPSTATE",
      key: "queryYear",
      payload: value
    });
  }
  async function fetchData(date) {
    let options = {
      hqUid: user.shopId,
      timestamp: date
    };
    dispatch(await fetchDataReceipts(user.shopId, options));
  }
  function computedObjReceipts(receipts) {
    let newObjReceipts = _.reduce(
      receipts,
      (acc, r, key) => {
        if (acc[r.storeUid] === undefined) {
          acc[r.storeUid] = {
            storeUid: r.storeUid,
            grandTotal: 0
          };
        }
        acc[r.storeUid].grandTotal += r.payment.grandTotal;

        return acc;
      },
      {}
    );
    return newObjReceipts;
  }
  function computedArrShopListForGraph(obj_receipts) {
    let newArr = [];
    _.forEach(shopList, s => {
      if (obj_receipts[s.uid]) {
        newArr.push({ ...obj_receipts[s.uid], name: s.name });
      } else {
        newArr.push({
          name: s.name,
          storeUid: s.uid,
          grandTotal: 0
        });
      }
    });
    return newArr;
  }
  function computedData(receipts) {
    
    let obj_receipts = {};
    obj_receipts = computedObjReceipts(receipts);
    let arr_shopList_forgraph = [];
    arr_shopList_forgraph = computedArrShopListForGraph(obj_receipts);

    ;

    setDataGraph(arr_shopList_forgraph);
    if (objShopList) {
      mapDataToOptionsChart(arr_shopList_forgraph);
    }
  }
  function mapDataToOptionsChart(dataGraph) {
    let newOptions = {};

    newOptions = {
      chart: {
        type: "column"
      },
      title: {
        text: "Summary"
      },

      xAxis: {
        categories: _.map(dataGraph, d => {
          let categories = [d.name];

          return categories;
        }),
        labels: {
          style: {
            fontSize: "16px"
          }
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: null
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true
      },
      plotOptions: {
       
        series: {
          dataLabels: {
            enabled: true,
            rotation: -90,
            color: "white",
            borderColor: "none",
            align: "right",
            // format: '{point.y:.1f}', // one decimal
            y: 10, // 10 pixels down from the top
            style: {
              fontSize: "auto",
              fontFamily: "Verdana, sans-serif",
              textOutline: "0px"
            }
          }
        }
      },
      series: [
        {
          name: "grandtotal",
          data: _.map(dataGraph, d => {
            let data = [d.grandTotal];

            return data;
          })
        }
      ]
    };

    setOptionsGraph(newOptions);
  }
  function createObjShopList() {
    let newObjShops = {};
    _.forEach(shopList, s => {
      if (s.branchId) {
        newObjShops[s.uid] = {
          name: s.name || "",
          uid: s.uid || ""
        };
      }
    });

    setObjShopList(newObjShops);
  }
  function clickGo() {
    let newQueryDate =
      queryMode === "date"
        ? queryDate
        : queryMode === "month"
        ? queryMonth
        : queryYear;

    fetchData(newQueryDate);
  }

  return (
    <div className="SummaryPageAll">
      <div class="row">
        {/* <h1>SumAll</h1> */}
        <div class="col-4 pr-0">
          <ToggleDateMode changequeryMode={changequeryMode} />
        </div>
        {queryMode === "date" && (
          <div class="col-8 pl-2">
            <DatePicker fetchData={fetchData} changeDate={changeDay} />
          </div>
        )}
        {queryMode === "month" && (
          <div class="col-8 pl-2">
            <div class="input-group mb-3">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                value={queryMonth}
                onChange={e => {
                  changeMounth(e.target.value);
                }}
              >
                {mounthList.map(m => {
                  return <option value={m.value}>{m.label}</option>;
                })}
              </select>
              <div class="input-group-append">
                <button
                  class="btn  btn-primary"
                  type="button"
                  id="button-addon2"
                  onClick={e => {
                    clickGo();
                  }}
                >
                  Go
                </button>
              </div>
            </div>
          </div>
        )}
        {queryMode === "year" && (
          <div class="col-8 pl-2">
            <div class="input-group mb-3">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                value={queryYear}
                onChange={e => {
                  changeYear(e.target.value);
                }}
              >
                {yearList.map(y => {
                  return <option value={y.value}>{y.label}</option>;
                })}
              </select>
              <div class="input-group-append">
                <button
                  class="btn  btn-primary"
                  type="button"
                  id="button-addon2"
                  onClick={e => {
                    clickGo();
                  }}
                >
                  Go
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div class="row">
        <div class="col-12">
          <CardGraph options={optionsGraph} />
        </div>
      </div>
    </div>
  );
};
export default SummaryPageAll;
