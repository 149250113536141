import React, { useState, useEffect } from "react";

import _ from "lodash";
import formatNum from 'format-num'

const CollapseItems = props => {
  
  const { Items, dataHolder } = props;
  ;

  const { name, id } = props;
  const [toggle, setToggle] = useState(false);

  const [thisGroupHasSales, setThisGroupHasSales] = useState(false);

  //   useEffect(() => {
  //      let found =  _.find(Items, (i)=>{
  //          return  objItems[i.name] && objItems[i.name].grandTotal
  //       })
  //       setThisGroupHasSales(found)
  //   }, [Items, objItems ])

  //   if(!thisGroupHasSales){
  //     return null
  //   }

  return (
    <div>
      <h6 
      onClick={() => setToggle(!toggle)}>
        {" "}
        {toggle ? "+" : "-"} {name}
        <small><em>  Click to expand</em></small>
      </h6>
    {toggle && <hr />}
    {!toggle && <ListItems items={Items} dataHolder={dataHolder} name={name}  />}
    </div>
  );
};
export default CollapseItems;

function ListItems({ items = [],dataHolder={} ,name =''}) {
  
  if (name!=='Product') {
    
    return (
      <div class="list-group list-group-flush mb-5">
        {_.map(items, i => {
            
          
          return (
            <li class="list-group-item d-flex justify-content-between align-items-center p-0 pt-1">
              <div>
                <h6>
                  <span style={{ color: "blue" }}>
                    {" "}
                    <b>{i.qty} </b>{" "}
                  </span>
                  <small> {i.name}</small>
                </h6>
              </div>
              <div>
                <span class="number">
                  {formatNum(i.price)}
                </span>
              </div>
            </li>
          );
        })}
      </div>
    );
  }
  return (
    <div class="list-group list-group-flush mb-5">
      {_.map(items, i => {
          
        // if (items.dataHolder[i.name] == undefined) {
        //   return null;
        // }
        return (
          <li class="list-group-item d-flex justify-content-between align-items-center p-0 pt-1">
            <div>
              <h6>
                <span style={{ color: "blue" }}>
                  {" "}
                  <b>{dataHolder[i.uid] && dataHolder[i.uid]} </b>{" "}
                </span>
                <small> {i.name}</small>
              </h6>
            </div>
            <div>
              <span class="number">
                {dataHolder[i.uid] && dataHolder[i.uid] * i.price}
              </span>
            </div>
          </li>
        );
      })}
    </div>
  );
}
