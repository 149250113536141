import React from 'react'

const CardMain = (props) => { 
        return (  
        <div className="Card">
            <div className="align-self-end" style={{flexGrow:1}}>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <center>
                                <span style={{fontSize:20,color:'#e2595f'}}>
                                    {props.value1}
                                </span>
                            </center>
                            <center>
                                <span>{props.title1}</span>
                            </center>
                        </div>
                       
                       
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
    
}
export default CardMain;