import React, { useEffect, useState } from "react";
// import moment from "moment";
import _ from "lodash";
import formatNum from "format-num";
import Card from "./Component/CardNPL";
import AlertNodata from "./Component/AlertNodata";
import { useSelector, useDispatch } from "react-redux";
import {setLoading} from './data/actions'


const BodyRateReport = props => {
  const dispatch = useDispatch()
  const { data = [] } = props;
  const [nodata, setNodata] = useState(false);
  const [receipts, setReceipts] = useState([]);
  const [receiptsSatRate, setReceiptsSatRate] = useState([]);
  const [filterMode, setFilterMode] = useState("therapist");
  const [arrTherapist, setArrTherapist] = useState({});
  const [selectedTherapist, setSelectedTherapist] = useState("all");

  const filterList = [
    { name: "Therapist" },
    { name: "Only comment" },
    { name: "Below 3" }
  ];

  useEffect(() => {
    
    let receipts_satrate = _.filter(data, d => {
      return d.satRate;
    });
    setReceiptsSatRate(receipts_satrate);
    createArrTherapist(receipts_satrate);
    filter(receipts_satrate);
  }, [data, filterMode]);

  useEffect(() => {
    let receipts_satrate = _.filter(data, d => {
      return d.satRate;
    });
    setReceiptsSatRate(receipts_satrate);
    filter(receipts_satrate);
  }, [selectedTherapist]);

  function filter(receipts_satrate) {
    let receipts_filter = receipts_satrate;
    if (filterMode === "therapist") {
      if (selectedTherapist === "all") {
        receipts_filter = _.filter(receipts_satrate, r => {
          // if (r.therapist.uid === null) {
          //
          //   alert(r.receiptId)
          //   console.log('no uid therapist')
          //   console.log('r')
          // }
          return r.therapist;
        });
        
      } else {
        receipts_filter = [];
        _.forEach(receipts_satrate, r => {
          if (r.therapist) {
            _.forEach(r.therapist, crouse => {
              _.forEach(crouse.therapists, t => {
                if (t.uid === selectedTherapist) {
                  receipts_filter.push(r)
                }
              });
            });
          }
        });
      }
    }
    if (filterMode === "only comment") {
      receipts_filter = [];
      _.forEach(receipts_satrate, r => {
        if (r.satRate.data) {
          _.forEach(r.satRate.data, ans => {
            if (ans.type === "string" && ans.value) {
              receipts_filter.push(r);
            }
          });
        }
      });
    }
    if (filterMode === "below 3") {
      receipts_filter = [];
      _.forEach(receipts_satrate, r => {
        if (r.satRate.data) {
          let answers = r.satRate.data;
          _.forEach(answers, a => {
            if (a.type === "rating" && a.value <= 3) {
              receipts_filter.push(r);
            }
          });
        }
      });
    }

    let receipts_filter_orderby = _.orderBy(
      receipts_filter,
      "timestamp",
      "desc"
    );
    

    setReceipts(receipts_filter_orderby);
    dispatch(setLoading(false))
  }
  function createArrTherapist(receipts) {
    let objTherapist = {};
    objTherapist = _.reduce(
      receipts,
      (acc, r, key) => {
        if (r.therapist) {
          _.forEach(r.therapist, course => {
            _.forEach(course.therapists, t => {
              if (acc[t.uid] === undefined) {
                acc[t.uid] = {
                  name: "",
                  uid: ""
                };
              }
              acc[t.uid].name = t.name;
              acc[t.uid].uid = t.uid;
              return acc;
            });
          });
        }
        return acc;
      },
      {}
    );
    let newArr = [];
    _.forEach(objTherapist, t => {
      newArr.push(t);
    });
    let newArr2 = [{ name: "All", uid: "all" }, ...newArr];
    ;
    // setSelectedTherapist(newArr[0].uid)
    setArrTherapist(newArr2);
  }
  function changeTherapist(uid) {
    setSelectedTherapist(uid);
  }
  function changeFilter(mode) {
    if (filterMode === "therapist") {
      setSelectedTherapist('all')
    }
    debugger
    setFilterMode(mode);
  }

  return (
    <div className="App">
      {/* {receiptsSatRate && receiptsSatRate.length >= 1 &&(
        <div
          class="row justify-content-between"
          style={{ paddingLeft: 20, paddingRight: 20 }}
        >
          {_.map(filterList, f => {
            return (
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  value={f.name}
                  checked={filterMode.toLowerCase() === f.name.toLowerCase() ? true : false}
                  onClick={e => {
                    changeFilter(f.name.toLowerCase());
                  }}
                />

                <label class="form-check-label" for="exampleRadios1" value={f.name} onClick={e=>{changeFilter(e.target.value.toLowerCase());}}>
                  {f.name}
                </label>
              </div>
            );
          })}
        </div>
      )} */}
      {receiptsSatRate && receiptsSatRate.length >= 1 && (
        <div
          class="row justify-content-between"
          style={{ paddingLeft: 20, paddingRight: 20 }}
        >
          {_.map(filterList, f => {
            return (
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  name={f.name}
                  id={f.name}
                  value={f.name}
                  checked={
                    filterMode.toLowerCase() === f.name.toLowerCase()
                      ? true
                      : false
                  }
                  onClick={e => {
                    changeFilter(f.name.toLowerCase());
                  }}
                />

                <label
                  class="form-check-label"
                  for={f.name}
                  value={f.name}
                  onClick={e => {
                    changeFilter(f.name.toLowerCase());
                  }}
                >
                  {f.name}
                </label>
              </div>
            );
          })}
        </div>
      )}
      {receipts.length >= 1 && filterMode === "therapist" && (
        <select
          class="form-control mt-2"
          onChange={e => {
            changeTherapist(e.target.value);
          }}
        >
          {_.map(arrTherapist, t => {
            return <option value={t.uid}>{t.name}</option>;
          })}
        </select>
      )}
      {receipts.length < 1 && (
        <div class="row">
          <div class="col-12 mt-2">
            <AlertNodata />
          </div>
        </div>
      )}
      {receipts.length >= 1 && (
        <p class="mt-2 mb-3">
          <span>จำนวนบิล {receipts.length} บิล</span>
          <span style={{ float: "right" }}>
            ยอดรวม{" "}
            <strong>
              {formatNum(_.sumBy(receipts, "payment.grandTotal"))}
            </strong>{" "}
            บาท
          </span>
        </p>
      )}

      {_.map(receipts, (x, key) => {
        if (x.satRate) {
          return (
            <div class="row mb-2 mt-2" key={key}>
              <Card items={x} />
            </div>
          );
        }
      })}
    </div>
  );
};

export default BodyRateReport;
